import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IDashboardType, INewsComponent, INewsParams } from 'features/dashboard/interface/dashboard';

import { FilledStar, UnfilledStar } from 'shared/icons/icon';
import { CardView } from 'shared/interface/enums';
import { handleArticleData } from 'shared/util/utility';

interface INewsContentProps {
	data: INewsComponent;
	cardIndex: number;
	section: string;
	className: string;
	view: string;
	newsData: IDashboardType<INewsComponent[], INewsParams>;
	onSaveNews: (newsId: number, action: boolean, index: number, section: string) => void;
}

const NewsContent: React.FC<INewsContentProps> = (props) => {
	const navigate = useNavigate();
	const { cardIndex, className, data, newsData, onSaveNews, section, view } = props;
	const navigationRoute = `/news/fullNewsArticle/${newsData.parent_id || 0}?categoryId=${newsData.params.kid}&newsId=${data.id}${
		newsData.params.whitelable_id ? `&whitelabelId=${newsData.params.whitelable_id}` : ''
	}`;
	const location = useLocation();
	const isIframe = location.pathname.includes('iframe');

	return (
		<div className={`${className || ''}`}>
			<div className='news-image cursor-pointer' onClick={() => navigate(navigationRoute)}>
				{data.bild && <img src={data.bild} alt='newsIcon' className='newsIcon' />}
			</div>
			<div className='news-data__wrapper d-flex justify-content-space-between align-items-start overflow-hidden'>
				<div className='news-data cursor-pointer' onClick={() => navigate(navigationRoute)}>
					<h1
						className={`font--16px line-height-22px font-weight--500 no-margin cursor-pointer ${
							(cardIndex > 1 || view === CardView.LIST_VIEW) && 'truncate--lines-2'
						} `}
						dangerouslySetInnerHTML={{ __html: handleArticleData(data.titel).replaceAll('<h1>', '<p>') }}
					/>
				</div>
				{newsData.type !== 'alert' && (
					<div className='star-icon__wrapper cursor-pointer' onClick={() => onSaveNews(data.id, data.gemerkte === 0, cardIndex, section)}>
						{data.gemerkte ? <FilledStar className='star-icon' /> : <UnfilledStar className='unfilled-star-icon' />}
					</div>
				)}
			</div>
			{view === CardView.GRID_VIEW && (cardIndex < 2 || isIframe) && (
				<p
					className='news-article-date font--16px line-height--22px font-weight--400 truncate--lines-6 cursor-pointer'
					dangerouslySetInnerHTML={{ __html: handleArticleData(data.inhalt).replaceAll('<h1>', '<p>') }}
					onClick={() => navigate(navigationRoute)}
				/>
			)}
		</div>
	);
};

export default NewsContent;
