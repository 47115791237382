import React, { PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';
import ColorLayout from 'shared/hoc/colorLayout/colorLayout';
import { CloseIcon } from 'shared/icons/icon';
import Button from '../form/button';

export interface ModalProps extends PropsWithChildren {
	show: boolean;
	handleClose?: () => void | React.ReactEventHandler<Record<string, never>>;
	className?: string;
	modalTitle?: string;
	modalTitle2?: string;
	dataTestId?: string;
}

const CustomModal: React.FC<ModalProps> = (props) => {
	return (
		<Modal show={props.show} onHide={() => (props.handleClose && props.handleClose) || false} className={`${props.className || ''}`}>
			<ColorLayout>
				{props.modalTitle && (
					<Modal.Header closeButton onHide={props.handleClose}>
						<Modal.Title>
							{props.modalTitle}
							&nbsp;{props.modalTitle2}
						</Modal.Title>
					</Modal.Header>
				)}
				{props.handleClose && (
					<Button className='modal-close-btn no-border' onClick={props.handleClose}>
						<CloseIcon width='16px' height='16px' className='close-icon no-padding' />
						<span className='sr-only'>Close</span>
					</Button>
				)}
				<Modal.Body>{props.children}</Modal.Body>
			</ColorLayout>
		</Modal>
	);
};

export default CustomModal;
