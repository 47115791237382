import * as actionTypes from 'store/action-types';
import { Action } from 'shared/interface';
import { ITenderReducerType } from '../interface/tenders';

const initialState: ITenderReducerType = {
	selectedsidePanelName: {
		value: '',
		label: ''
	}
};

const tenderReducer = (state: ITenderReducerType = initialState, action: Action): ITenderReducerType => {
	switch (action.type) {
		case actionTypes.GET_SELECTED_SIDE_PANEL_NAME:
			return {
				selectedsidePanelName: action.payload
			};
		default:
			return state;
	}
};
export default tenderReducer;
