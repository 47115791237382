import { Translation } from './constants';

export const en: Translation = {
	loginText: 'Raw material prices à la carte',
	forgotYourPassword: 'Forgott password?',
	signUp: 'Sign up',
	eMail: 'E-Mail',
	registered: 'Registered',
	customerNr: 'Customer Nr.',
	freemium: 'Freemium',
	customer: 'Customer',
	animateWidgetLine: 'Animated Dashboard Widgets',
	showPartnerLinkLine: 'Show Partner Section in Nav Bar',
	animation: 'Animations',
	personalize: 'Personalization',
	searchMaterialEtc: 'Search by material, etc.',
	searchNewsEtc: 'Search by news, etc.',
	noTendersSaved: 'No tenders saved',
	noEarlyProjectInfoSaved: 'No early project information saved',
	tendersAndMore: 'Tenders & more',
	frozen: 'Frozen',
	unfreeze: 'Unfreeze',
	freeze: 'Freeze',
	adminDownloadIntraday: 'Admin Download (Intr.)',
	passwordConfirmation: 'Password Confirmation',
	clickHereTo: 'Click here to',
	forgotPasswordWaitMessage:
		'If your email address is known, then you will receive an email from us in the next few minutes. If you do not receive an email after 5 minutes, then please also check your spam folder. Alternatively, you can also contact customer service.',
	oldPasswordIsRequired: 'Old password is required',
	passwordMatch: 'New password & confirm new password do not match!',
	spaceNotExists: 'Space should not exists',
	passwordCriteria: 'Your password must meet the following criteria:',
	oneSpecialChar: 'Min. 1 special character',
	oneNumberValidation: 'Min. 1 number',
	oneUppercaseOneLowercase: 'Min 1 capital and 1 small letter',
	atLeast8Characters: 'Min 8 & Max 24 characters',
	matfoxxSettings: 'Matfoxx settings',
	matfoxxNotifications: 'Notifications',
	matfoxxNotificationOff: 'Off',
	matfoxxNotificationOn: 'On',
	notificationPeriod: 'Notification period',
	notificationAutomatically: 'Automatically',
	until: 'Until',
	filterNotifications: 'Filter notifications',
	quoteOTheDay: 'Quote of the day',
	matflixxOffers: 'Matflixx offers',
	updateGroup: 'Update group',
	subGroup: 'Sub Group',
	deleteCourseList: 'Are you sure you want to delete this item..?',
	createGroup: 'Create Group',
	editModelParameters: 'Edit model parameters',
	addToCostModal: 'Add to Cost Model',
	matflixxModals: 'Matflixx models',
	addMatflixxModals: 'Add Matflixx models',
	customizeCostModal: 'Customize Cost Model',
	sinceOneYear: 'Since 1 year',
	sinceOneQuater: 'Since 1 quarter',
	sinceOneMonth: 'Since 1 month',
	shares: 'Shares',
	priceDriver: 'Price Driver',
	share: 'Share',
	updateOnDashboard: 'Update on Dashboard',
	myCostModal: 'My Cost Model',
	dashboard: 'Dashboard',
	customize: 'Customize',
	favorites: 'Favorites',
	myData: 'My data',
	myDashboard: 'My dashboard',
	noUserFound: 'No user found',
	myNewsAndForecast: 'My news and forecasts',
	designation: 'Designation',
	addShortcut: 'Add a new shortcut',
	myShortcut: 'My Shortcuts',
	pleseSelectFieldMessage: 'Please fill this field',
	targetUrl: 'Target URL',
	enterValidUrlAddress: 'Enter valid URL address',
	urlForLogoPriview: 'URL for Logo or Preview (optional)',
	rawMaterials: 'Raw Materials',
	news: 'News',
	selectedMaterials: 'Selected materials',
	selectedNews: 'Selected news',
	addWidgetToDashboard: 'Add widget to dashboard',
	update: 'Update',
	submit: 'Submit',
	delete: 'Delete',
	edit: 'Edit',
	showAll: 'Show All',
	date: 'Date',
	price: 'Price',
	change: '%-Change',
	latestNews: 'Latest news',
	addWidget: 'Add widget',
	allSectors: 'All sectors',
	collapsePanel: 'Collapse panel',
	noCourseListing: 'No course listings',
	convert: 'Convert',
	addToDashboard: 'Add to dashboard',
	exportCorseListAsExcel: 'Export course list as Excel',
	advanceSearchForCourses: 'Advanced search for courses',
	seek: 'Search',
	searchPatterns: 'Search patterns / words',
	advanceSearchPlaceHolder: 'Minimum 3 character such as "electricity"',
	duration: 'Duration',
	doNotMetter: `Doesn't matter`,
	withoutDueDate: 'Without due date',
	withoutFutures: 'Without futures',
	futurePerptualContracsOnly: 'Future endless contracts only',
	futureWithoutEndlessContract: 'Futures without endless contracts',
	back: 'Back',
	materials: 'Materials',
	addFilterToDashboard: 'Add filters to dashboard',
	chooseDateRange: 'Choose date range',
	cancel: 'Cancel',
	searchNews: 'News',
	searchNewsMaterialEtc: 'Search by news, prices, etc.',
	all: 'All',
	noEntriesFound: 'No results found',
	tryUsingDiffKeyword: 'Try using different keywords',
	more: 'More...',
	notification: 'Notification',
	profile: 'Profile',
	logout: 'Logout',
	login: 'Log in',
	emailAddress: 'E-Mail-Address',
	password: 'Password',
	exportHistoryAsCSV: 'Export as CSV',
	exportDataAsExcel: 'Export as Excel',
	add: 'Add',
	maturityOverview: 'Maturity overview',
	exportPriceAsExcel: 'Export price as Excel',
	addWidgetToScreeen: 'Add to widget screen',
	setUpPriceAlert: 'Set up price alert',
	addChartToDashboard: 'Add chart to dashboard',
	courseListings: 'Course lists',
	renameCourseList: 'Rename course list',
	rename: 'Rename',
	extinguish: 'Delete',
	newsFilterTags: 'News Filter / Tags',
	alerts: 'Alerts',
	notifyAtTime: 'Notify at ... time (optional) ',
	noteMessageForAllKeywordAppears: 'Note: The notification only occurs if all keywords appear in a message!',
	addNews: 'New filter',
	currnetCourse: 'Current course',
	reportIf: 'Alert when',
	courseExceeded: 'Course exceeded',
	courseUndershot: 'Course undershot',
	absPerDeviationExceeded: '% positive deviation',
	comparativeValue: 'Comparative value',
	dateOfExpiry: 'Expiration date',
	addCourseAlert: 'Add alert',
	messageAlerts: 'Message alerts',
	keywords: 'Keywords',
	exmpleEleGas: 'e.g. electricity gas',
	forecasts: 'Forecasts',
	pdfModule: 'My PDFs',
	name: 'Name',
	noNewsHeading: 'No news headings...',
	createNewPriceList: 'Create a new price list',
	addAnotherOneLanguage: 'Add another language',
	view: 'View',
	uploadFile: 'Upload file',
	maturity: 'Maturity',
	symbol: 'Symbol',
	visible: 'Visible',
	notVisible: 'Not Visible',
	pricesTime: 'Prices time',
	selectAll: 'Select all',
	hideSelected: 'Hide selected',
	deleteSelected: 'Delete selected',
	editUserData: 'Edit user data',
	save: 'Save',
	generalInfo: 'General info',
	salutation: 'Salutation',
	mr: 'Mr',
	ms: 'Ms',
	surname: 'Surname',
	company: 'Company',
	contacts: 'Contacts',
	phoneNumber: 'Phone number',
	email: 'E-mail',
	permissionData: 'Permission data',
	status: 'Status',
	active: 'Active',
	admin: 'Admin',
	inactive: 'Inactive',
	validUntill: 'Valid until',
	apiInterfaceActive: 'API interface active',
	apiKey: 'API-key',
	comment: 'Comment',
	superAdminMessage: 'Super admin',
	// , i.e. allowed to change admins and special tables',
	adminMessage: 'User Admin',
	// , i.e. is allowed to change users and user rights, as well as everything that editors / editors are	allowed',
	allMaterials: 'All materials',
	myMaterials: 'My materials',
	search: 'Search',
	saveMessage: 'Saved News',
	constModels: 'Cost Models',
	courses: 'Courses',
	dataProcessorMessage: 'Data Admin',
	// , i.e. allowed to change course, histories and mbi course lists',
	editorMessage: 'Editor',
	// , i.e. allowed to change and delete messages',
	API: 'API',
	statistics: 'Statistics',
	validity: 'Validity',
	markant: 'Distinctive',
	manageData: 'Manage data',
	permission: 'Permission',
	ownDataUpload: 'Own data upload',
	invisible: 'Invisible',
	whiteLabelAdmin: 'Whitelabel users',
	groupName: 'Group name',
	files: 'Files',
	addFile: 'Add file',
	dragAndDropToUploadFile: 'Drag & Drop to upload file',
	uploaded: 'Uploaded',
	timeLimit: 'Time limit',
	occurred: 'Occurred',
	deactive: 'Deactive',
	isin: 'ISIN',
	exchange: 'Exchange',
	dataProvider: 'Data provider',
	source: 'Source',
	cpCustomer: 'CP Customer',
	courseTime: 'Course time',
	country: 'Country',
	currency: 'Currency',
	pleaseSelectACategory: 'Please select a category',
	chooseCountry: 'Choose country',
	runTime: 'Runtime',
	chooseCurrency: 'Choose currency',
	advanceSearchfForPrice: 'Advance search for prices',
	advanceSearchTableText: 'There are more prices matching your search. Scroll forward. However, a maximum of 200 results are displayed.',
	advanceSearchTableDescription:
		'Enter more search words to refine the result and narrow the search. Also use abbreviations or DIN / AISI codes. Vary between the German and English names. Or put a minus in front of words, e.g.- Germany, to exclude price data that have this word in the name.',
	tip: 'Tip',
	line: 'Line',
	area: 'Area',
	candle: 'Candle',
	chartType: 'ChartType',
	indicators: 'Indicators',
	span: 'Period',
	addToPrice: 'Add to my materials',
	currentPrice: 'Current price',
	alertCurrency: 'Currency (original)',
	addedToDashboard: 'Added to dashboard!',
	updatedToDashboard: 'Updated on Dashboard!',
	exportData: 'Export data',
	selectFileFormat: 'Select the file format',
	users: 'Users',
	general: 'General',
	data: 'Data',
	whiteLabel: 'Whitelabel',
	matfoxx: 'Matfoxx',
	advancedSearchResult: 'The search returns no results.',
	profileData: 'Profile & Data',
	profileInfo: 'Profile info',
	profileSaveChanges: 'Save changes',
	profilePhoto: 'Profile photo',
	profileAddFile: 'Add file',
	profileDragandDrop: 'or Drag & Drop here',
	profileSavePhotos: 'Save photo',
	profileDeletePhotos: 'Delete photo',
	profileGeneralData: 'General data',
	profileCurrentEmail: 'Current email address',
	profileChangePassword: 'Change password',
	advancedSearchTip:
		'Tip: Enter more search words to refine the result and narrow the search. Also use abbreviations or DIN / AISI codes. Vary between the German and English names. Or precede words with a minus sign, eg - Germany, to exclude price dates that have this word in the name.',
	adminDownloadHistory: 'Admin download (Hist.)',
	addToMbiList: 'Add to MBI price list',
	assignMaterialToUser: 'Assign material to a user',
	dataEditing: 'Data editing',
	addCourseToCourseList: 'Add a course to a course list',
	myMaterialsEmptyTitle: 'This price list is empty!',
	myMaterialEmptyDescription:
		'To add materials or prices to this price list, please select a material/price using the search function or via Open all materials and then click on "Add to a price list". Then select the name of this price list.',
	searchUsers: 'Search users',
	registerNow: 'Register',
	city: 'City',
	position: 'Position',
	postcode: 'Postcode',
	required: 'Required',
	firstname: 'First name',
	download: 'Download',
	imprint: 'Imprint',
	settings: 'Settings',
	abort: 'Cancel',
	takeOver: 'Apply',
	removed: 'Remove',
	days: 'Days',
	setPeriod: 'Set period',
	onboardSecondTitle: 'Which form of presentation do you prefer?',
	createPassword: 'Create Password',
	futures: 'Futures',
	masterExportExcel: 'Master data download',
	enterValuesGreaterThan: 'Enter value greater than',
	enterValuesLessThan: 'Enter value less than',
	comparisonValues: 'Comparison value must be between 0 - 100',
	comparisonValueGreaterThanZero: 'Comparison value must be greater than zero',
	numberIsTooBig: 'Number is too big',
	courseDifference: 'Procentual deviation of the course exceeded',
	selectFromTheDropDown: 'Please select from the dropdown',
	title: 'Title',
	url: 'URL',
	urlplaceholder: 'https://...',
	registrationValidationType: 'Invalid type',
	addShortCutDeleteLink: 'Delete link',
	comparativeSearchPlaceholder: 'Comparative value search',
	month: 'Month',
	months: 'Months',
	year: 'Year',
	custom: 'Custom',
	emptyChartMessage: 'Unfortunately, data could not be loaded because the last course is more than a year old!',
	ema: 'Exponential Moving Average (EMA)',
	sma: 'Simple Moving Average (SMA)',
	bb: 'Bollinger Bands (BB)',
	tma: 'Triangular Moving Average (TMA)',
	wma: 'Weighted Moving Average (WMA)',
	atr: 'Average True Range (ATR)',
	rsi: 'Relative Strength Index (RSI)',
	macd: 'Moving Average Convergence/Divergence (MACD)',
	sto: 'Stochastic Oscillator (STO)',
	lowDataChartMessage: 'No or too few courses!',
	choose: 'Choose',
	noAlertFound: 'No alert found',
	loadingChartData: 'Loading chart data...',
	deleteDashboardWidget: 'Sure you want to delete this item?',
	positiveDifference: '% positive deviation',
	negativeDifference: '% negative deviation',
	updateChartToDashboard: 'Update chart on dashboard',
	day: 'Day',
	daily: 'Daily',
	weekly: 'Weekly',
	monthly: 'Monthly',
	updateToDashBoard: 'Update to dashboard',
	addMaterials: 'Add materials',
	addNewsAndForecast: 'Add news and forecasts',
	unlimited: 'Unlimited',
	markAllAsRead: 'Mark all as read',
	otherChanges: 'Other changes',
	materialAlert: 'Material Alerts	',
	newslAlert: 'News Alerts',
	actual: 'Current',
	hist: 'Historic',
	high: 'High',
	low: 'Low',
	histDate: 'Hist. Date',
	unit: 'Unit',
	kurszeit: 'Date',
	dueDate: 'Due Date',
	mbiListPlaceHolder: 'Set caption',
	isRequired: 'is required!',
	confirmLogout: 'Are you sure, you want to logout?',
	yes: 'Yes',
	no: 'No',
	faq: 'FAQ',
	almostThere: 'Almost there!',
	weNeedInfo: 'We just need a little more information.',
	bis: 'To',
	von: 'From',
	protocol: 'Protocol',
	categoryIncludeMessage: 'This material is included in the following price lists :',
	userIsAdminMessage: 'The user {{userName}} is an admin and can see everything anyway, so no data is displayed here.',
	editNews: 'Edit News',
	deleteNews: 'Delete News',
	adminPriceDownload: 'Admin Price Download',
	userId: 'User Id',
	uploadedBy: 'Uploaded By',
	fileName: 'Filename',
	inserted: 'Inserted',
	updated: 'Updated',
	processes: 'Processes',
	mistake: 'Mistake',
	noGroup: 'No Group',
	enterEmail: 'Please enter valid email',
	forgotPasswordNote: 'If you request a new password, you will receive an email from us with a link to set a new password.',
	requestNewPassword: 'Request new Password',
	alreadyRegistered: 'If you are already registered, then',
	here: 'here',
	notSubscribeYet: 'You are not subscriber yet? Then',
	register: 'Register',
	now: 'now',
	passwordForgotten: 'Password forgotten',
	testForFree: 'and test Matflixx for free.',
	noSavedNewsFound: 'You have not marked any messages...',
	saveChanges: 'Save changes',
	discard: 'Discard',
	lastName: 'Last name',
	removeFromDashboard: 'Removed from Dashboard',
	noMaterialFound: 'No material found',
	takeOverAccount: 'Take over',
	takeOverFirstMessage: 'You have taken over this user account. Changes you make actually affect the customer account. Click',
	takeOverSecondMessage: ', to return to your account.',
	newsSections: 'News sections...',
	itemChoosen: 'items chosen.',
	group: 'Group',
	authorized: 'Authorized',
	mainCourse: 'Main course',
	alertWhen: 'Alert when',
	entered: 'Entered',
	newsFilterKeyWords: 'News Filter / Keywords',
	deleteAlertMessage: 'Do you want to delete the rate monitoring for {{alert}} ?',
	visibility: 'Visibility',
	hidden: 'Hidden',
	addPartnersLink: 'Add partners link',
	create: 'Create...',
	savedNews: 'Saved news.',
	shortcutCreated: 'Added shortcut.',
	shortcutUpdated: 'Applied changes.',
	shortcutDeleted: 'Shortcut deleted.',
	widgetSwiped: 'The position of the elements has been changed.',
	grantPermissionToUser: 'The user rights have been updated!',
	deleteNewsArticle: 'Article deleted.',
	deleteFromCourseList: 'Entry deleted from the course list!',
	addedToList: 'Added to the list!',
	assign: 'Assign',
	forgotPassword: 'Forgot password',
	priceListIsEmpty: 'This price list is empty!',
	emptyPriceListMessage:
		'To add materials or prices to this price list, please select a material/price using the search function or use "All materials" and then click on "Add to a price list". Then select the name of this price list.',
	deleteNewsPopupMessage: 'Are you sure you want to delete news?',
	validUrlErrorMessage: 'Please enter valid URL',
	addInformation: 'Add information',
	enterNote: 'Enter note',
	choosePriority: 'Choose priority',
	normal: 'Normal',
	critical: 'Critical',
	editInformation: 'Edit information',
	authorizationElement: 'Authorization element',
	userList: 'User list',
	masterData: 'Master data',
	backpack: 'Backpack',
	createNewBackpack: 'Create a new backpack',
	editBackpack: 'Edit backpack',
	downloadMasterData: 'Download master data',
	comparisionValueShouldNotBeEmpty: 'Comparision value should not be empty',
	reset: 'Reset',
	whiteLabelFormTitle: 'Change UI color',
	colors: 'Colors',
	courseListNameHasBeenSaved: 'Course list name has been saved!',
	courseListDeleted: 'Course list deleted',
	noCurrentData: 'No current data ',
	maximumLimitReached: 'Maximum limit reached',
	noNewsArticleFound: 'No news found',
	additionalInfo: 'Additional info',
	chartUpdateDaily: 'Daily',
	chartUpdateWeekly: 'Weekly',
	chartUpdateMonthly: 'Monthly',
	partnerLinks: 'Partner links',
	addAlertDropDownPlaceholder: 'Select...',
	listName: 'List name',
	glossary: 'Glossary',
	normalization: 'Normalization',
	upload: 'Upload',
	listofOffers: 'List of offers',
	myModels: 'My models',
	exportAsExcel: 'Export data as Excel',
	exportAsCSV: 'Export history as CSV',
	createNewCostModal: 'Create new Cost Model',
	noDataFound: 'No data found',
	modalName: 'Model Name',
	sectors: 'Sectors',
	makeThisModelVisible: 'Make this model visible',
	ingridients: 'Ingredients',
	number: 'No.',
	displayName: 'Component Name',
	amount: 'Amount',
	ratio: 'Ratio',
	total: 'Total',
	saveAsDraft: 'Save as draft',
	createCostModal: 'Create Cost Model',
	addNewsDashboard: 'Add news',
	loadMore: 'Load More',
	matfoxxOffersFormHeader: 'Displayed text, action, duration',
	fileHasBeenUploaded: 'File has been uploaded',
	enterTheOfferText: 'Enter the offer text',
	enterValue: 'Enter value',
	text: 'Text',
	link: 'Link',
	jokes: 'Jokes',
	enterJoke: 'Enter a joke',
	listOfJokes: 'List of jokes',
	jokeOfTheDay: 'JOKE OF THE DAY',
	offerOfTheDay: 'OFFER OF THE DAY',
	heading: 'Heading',
	entry: 'Entry',
	listOfAnnouncements: 'List of announcements',
	allDocuments: 'All documents',
	editPrice: 'Edit price',
	howWouldYouLikeToProceed: 'How would you like to proceed?',
	generateDashBoardAuomatic: 'Generate a Dashboard automatically',
	customerServiceAssistence: 'Customer service assistance',
	createYourOwnDashboard: 'Create your own Dashboard',
	balancedDashboard: 'Balanced Dashboard',
	graphicalDashboard: 'Graphical Dashboard',
	analyticalDashboard: 'Analytical Dashboard',
	next: 'Next',
	addNewForecast: 'Add a new forecast',
	addNewPdfModule: 'Add a new PDF',
	pleaseUploadFileFirst: 'Please upload file first*',
	oldPassword: 'Old password',
	newPassword: 'New password',
	confirmNewPassword: 'Confirm new password',
	passwordChanged: 'Password changed',
	forgotPasswordMailSendMessage: `We'll email you within minutes.`,
	sector: 'Sector',
	category: 'Category',
	imageSources: 'Image sources',
	chartUpdateQuarterly: 'Quarterly',
	forecastDelete: 'forecast deleted!',
	pdfModuleDelete: 'PDF deleted!',
	week: 'Week',
	prices: 'Prices',
	logarithmicYAxis: 'Logarithmic Y-axis',
	informations: 'Informations',
	frequency: 'Frequency',
	stockExchange: 'Stock exchange',
	dataAvailability: 'Data availability',
	addInfo: 'Add. info',
	addedOn: 'Added on',
	endedOn: 'Ended on',
	graphicDownload: 'Export graphics',
	adminDownloadForecast: 'Admin download (Forecast)',
	adminDownloadPdfModule: 'Admin download (PDF)',
	default: 'Default',
	inventoryChart: 'Inventory chart',
	forwardCurve: 'Forward curve',
	uploadActiveSVG: 'Upload active SVG Icon',
	uploadInActiveSVG: 'Upload in-active SVG icon',
	tenders: 'Tenders',
	saveTenders: 'Saved tenders',
	projectEarlyInformation: 'Early project information',
	countries: 'Countries',
	noTenderArticleFound: 'No tenders found',
	created: 'Created',
	validFrom: 'Valid from',
	validTo: 'Valid to',
	deadLine: 'Deadline',
	financing: 'Financers',
	gtaiId: 'GTAI ID',
	projectName: 'Project name',
	referenceNr: 'Reference number',
	latestTenders: 'Latest tenders',
	tenderAlerts: 'Tenders alerts',
	tenderFilterKeywords: 'Tender filter / keywords',
	attachment: 'Anhänge',
	noSavedTenders: 'You have not saved any tenders...',
	chartSettings: 'Chart settings',
	left: 'Left',
	right: 'Right',
	color: 'Color',
	indicator: 'Indicator',
	history: 'History',
	axis: 'Axis',
	short: 'Short',
	long: 'Long',
	advancedSettings: 'Advanced settings',
	signal: 'Signal',
	duplicateChartTooltipMessage:
		'Duplicates the rate to make historical comparisons in the year period or comparisons in different currencies with the already selected material / data set.',
	defaultTooltipMessage: 'Charting of price data',
	indicatorTooltipMessage: 'Selection of the indicators to be used. Several indicators can be used simultaneously.',
	axisTooltipMessage: 'Position of the Y-axis',
	historyTooltipMessage: 'Selection of history by year',
	emaToolTipMessage: 'Average asset price, new and recent data points/prices carry greater weight.',
	smaToolTipMessage: 'Average price of an asset/security over a specific period of time.',
	stoToolTipMessage: 'A closing price of a security is compared to a range of its prices over a period of time.',
	macdToolTipMessage: 'An indicator to understand the momentum and directional strength of a security.',
	rsiToolTipMessage: 'Measures the speed and change of price movements.',
	bbToolTipMessage: 'Technical analysis tool.',
	tmaToolTipMessage: 'The average of the SMA. Wont react quick to volatile market conditions.',
	wmaToolTipMessage: 'Assigns a heavier weight to current data points.',
	atrToolTipMessage: 'Used to measure a securities price movement and based on absolute values.',
	changeToolTipMessage: 'Charting of the change from the previous data point',
	LogarithmicYAxisTooltipMessage: 'Allows better visualization of high amounts',
	exportFormat: 'Export format',
	grouping: 'Grouping',
	sorting: 'Sorting',
	allData: 'All data',
	intraDay: 'Intra day',
	chartUpdateYearly: 'Yearly',
	yearly: 'Yearly',
	value: 'Value',
	mustBeNumber: 'must be a number',
	numberTableHeader: 'Numbers',
	shortCustom: 'custom.',
	standard: 'Standard',
	advanced: 'Advanced',
	ascending: 'Ascending',
	descending: 'Descending',
	standardInfo: 'Contains price data and chart',
	advancedInfo: 'Standard export expanded to include additional data (if available), such as percentage change and forwards',
	allDataInfo: 'Export of daily data points or closing price of intraday courses',
	intradayInfo: 'Export of all price data within a trading day',
	weeklyInfo: 'Export of weekly data points or weekly averages of the available data',
	monthlyInfo: 'Export of monthly data points or monthly averages of the available data',
	ascendingInfo: 'Sorting from oldest to newest data point',
	descendingInfo: 'Sorting from newest to oldest data point',
	bar: 'Bar',
	barChartErrorMessage: 'Percentage change only visible in the line chart',
	barChartNumberValidationMessage: 'Bar chart only supports a maximum of 3 comparison prices',
	generatingChartImages: 'Generating chart images...',
	intraDayInfo: 'Export of all price data within a trading day',
	years: 'Years',
	noEnoughDataToPlot: 'Not enough data to plot.',
	createNewGroup: 'Create a new group',
	none: 'None',
	dataNotIncludedInSubscription: 'This data is not currently included in your subscripton.',
	pleaseSelectAllHistoryDropdown: 'Histories can only be compared to other histories.',
	groupManagement: 'Group management',
	addNewGroup: 'Add new group',
	promotionCode: 'Promotion Code',
	selectUser: 'Select user',
	selectBackpack: 'Select backpack',
	selectWhiteLabel: 'Select white label',
	groups: 'Groups',
	editGroup: 'Edit Groups',
	customMailCommunication: 'Custom mail communication',
	adjustMailTemplate: 'Adjust Mail Templates',
	chooseUser: 'Choose user',
	fileUploadSuccessMessage: 'File upload successfully',
	noWhiteLabelFoundErrorMessage: 'No whitelable found! Please click on "+" to create a new one.',
	registration: 'Registration',
	projectAlerts: 'Project alerts',
	copyToClipboard: 'Copied to Clipboard',
	somethingWentWrong: 'Something went wrong :confused:',
	phone: 'Phone',
	foreignTradeNews: 'Foreign Trade News',
	financers: 'Financers',
	deleteUser: 'Delete user',
	deleteUserMessage: 'To delete the user type in “DELETE”',
	deleteMyMaterialConfirmationMessage: ' Are you sure you want to remove material?',
	deleteMyMaterialListEntriesConfirmationMessage: 'Are you sure you want to delete list?',
	groupName50CharacterValidation: 'Group name must be at most 50 characters',
	groupNameWhiteSpaceValidationMessage: 'Group name cannot have more than one whitespace between words',
	userDashboard: 'User Dashboards',
	dashboardAssignSuccessfully: 'Dashboard assigned successfully!',
	myMaterialListTransferredSuccessfully: 'My material list transferred successfully',
	transfer: 'Transfer',
	acceptAtmax10Numbers: 'Accept at-max 10 numbers',
	firstNameCharacterLengthValidation: 'First name must be at most 30 characters',
	lastNameCharacterLengthValidation: 'Last name must be at most 30 characters',
	germanNameCharacterLengthValidation: 'German name must be at most 50 characters',
	englishNameCharacterLengthValidation: 'English name must be at most 50 characters',
	reports: 'Reports',
	allReports: 'All Reports',
	matflixxForecast: 'Matflixx Forecasts',
	matflixxPdfModule: 'Matflixx PDFs',
	allForecasts: 'All Forecasts',
	allPdfModule: 'All PDFs',
	clearAll: 'Clear All',
	deleteBackPackMessage: 'To delete the backpack type in “DELETE”',
	inActivityMessage: 'You have been logged out due to inactivity. Please log in again.',
	otherDeviceLoginMessage: 'It appears that you are using Matflixx on a different device. For more information, please contact customer service.',
	matflixxSectors: 'Matflixx sectors',
	moveWidgetHere: 'Move widget here',
	imageValidationMessage: 'File size is too large. Please select an attachment which is less than 1 MB.',
	imageFormateValidationMessage: 'Unsupported file format. Only JPEG, JPG, and PNG formats are supported.',
	germanName: 'German name',
	englishName: 'English name',
	activeIcon: 'Active icon',
	inActiveIcon: 'In-active icon',
	customMaterialStructure: 'Custom material structure',
	customNewsStructures: 'Custom news structure',
	customForecastStructure: 'Custom Forecast structure',
	customPdfModuleStructure: 'Custom PDF structure',
	activePlusOriginal: 'active (+show original)',
	hideAll: 'Hide all',
	actualData: 'Actual data',
	deleteBackpack: 'Delete Backpack',
	chooseMasterData: 'Choose Master Data',
	chooseSource: 'Choose Source',
	enterSourceName: 'Enter source name',
	exportVisibleMaterialOnly: 'Export visible materials only',
	alsoExportFuturesContracts: 'Also export futures contracts',
	exportCsv: 'Export as CSV',
	exportExcel: 'Export as Excel',
	devices: 'Devices',
	lastLogin: 'Last log-in',
	minutes: 'Minutes',
	dailyActiveUsers: 'Daily active user',
	monthlyActiveUsers: 'Monthly active user',
	weeklyActiveUsers: 'Weekly active user',
	totalUsers: 'Total user',
	newUsers: 'New user',
	averageTimeSpend: 'Average time spent',
	averagePageVisits: 'Average page visits',
	downloadFilesheet: 'Download filesheets',
	currencies: 'Currencies',
	units: 'Units',
	countriesAndRegions: 'Countries & Regions',
	notations: 'Notations',
	tendersCountries: 'Tenders countries',
	askMeSomething: 'Ask me something',
	customerSince: 'Customer since',
	freemiumSince: 'Freemium since',
	language: 'Language',
	logins: 'Logins',
	triggered: 'Triggered'
};
