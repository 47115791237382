import { AxiosError } from 'axios';
import { formatLocale } from 'd3-format';
import * as ExcelJS from 'exceljs';
import {
	AdminMasterData,
	AdminMaterialListEntries,
	IAdminBackpackMasterData,
	IAllMaterialType,
	IAllNewsCategoriesList,
	IAllNewsType,
	IApiMasterData,
	IBackpackForecastList,
	IBackpackPdfModuleList,
	IBackpackNewsList,
	IBackpackUserList,
	IBakcpackMaterialList,
	IForecastGroupData,
	IPdfModuleGroupData,
	IForecastsListType,
	IPdfModuleListType,
	IGroupUserListDownload,
	IMaterialGroupData,
	IMatfoxxJokesList,
	IMatfoxxOffersList,
	INewsGroupData
} from 'features/admin/interface/admin';
import { handleNameKey } from 'features/admin/util/utility';
import { IDashboardData } from 'features/dashboard/interface/dashboard';
import { IChartInformation, IChartSettingsType, IExcelHeader } from 'features/futuretable/interface/futureTable';
import { IAdminSearchMaterialCourseList, ICSVData, ICourseList, IExportData } from 'features/material/interface/material';
import { saveAs } from 'file-saver';
import i18n, { TFunction } from 'i18next';
import { filter, isEmpty } from 'lodash';
import moment from 'moment';
import {
	API_CONFIG,
	DateRangeMapper,
	adminAdvancedSearchCsvHeaders,
	adminListEntriesHeader,
	adminMasterDataHeaders,
	apiMasterDataCsvHeaders,
	customCsvHeaders,
	forecastBackpackMasterDataHeaders,
	pdfModuleBackpackMasterDataHeaders,
	getDifferenceDaysForOneYear,
	groupUserListHeader,
	matfoxxJokesListHeaders,
	matfoxxOffersListHeaders,
	newsBackpackMasterDataHeaders,
	newsListHeaders,
	userListBackpackMasterDataHeaders
} from 'shared/constants/constants';
import {
	AddInfoIcon,
	AddedOnIcon,
	CategotyIcon,
	CurrencyUnitIcon,
	DataAvailabilityIcon,
	EndedOnIcon,
	FrequencyIcon,
	NameIcon,
	SourceIcon,
	StockExchangeIcon
} from 'shared/icons/icon';
import { ChartAxisPosition } from 'shared/interface/enums';
import httpService from 'shared/services/http.service';
import * as actionTypes from 'store/action-types';
import { Action, IDropDownOption, State } from '../interface';
import localizationConstants from './translation/constants';

export const createAction = (ACTION: string, data: any = null): Action => ({
	type: ACTION,
	payload: data
});

export const createLoadingSelector = (actions: string[]) => (state: State) => {
	// returns true only when all actions is not loading
	let loader = false;
	for (let i = 0; i < actions.length; i += 1) {
		if (state.loading.api[actions[i]]) {
			loader = true;
			break;
		}
	}
	return loader;
};

/**
 * function which returns formatted date
 * @param date
 */
export const formatDate = (date: any, format?: string) => {
	if (!date) {
		return '';
	}
	return moment(date)
		.local()
		.format(format?.toUpperCase() || 'DD-MM-YYYY');
};
export const capitalizeFirstLetter = (string: string) => string && string.charAt(0).toUpperCase() + string.slice(1);

export const handleError = (error: AxiosError) => {
	const isError = error.response && error.response.data;
	if (isError) {
		return error.response?.data.message;
	}
	return 'Something went wrong';
};

export const debounce = <T extends (...args: any[]) => void>(func: T, wait = 400) => {
	let timeout: NodeJS.Timeout | null = null;

	const debounced = (...args: Parameters<T>) => {
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => func(...args), wait);
	};

	debounced.cancel = () => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
	};

	return debounced as T & { cancel: () => void };
};

export const customNumberFormatter = (value: number, maxFractionLImit?: number) => {
	if (
		new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DEM', maximumFractionDigits: maxFractionLImit }).format(value).includes('DEM')
	) {
		return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DEM', maximumFractionDigits: maxFractionLImit })
			.format(value)
			.split('DEM')[0];
	}
	if (
		new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DEM', maximumFractionDigits: maxFractionLImit }).format(value).includes('DM')
	) {
		return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DEM', maximumFractionDigits: maxFractionLImit })
			.format(value)
			.split('DM')[0];
	}
};

export const windowScroll = (value: number, position: 'top' | 'bottom' = 'top', behavior: 'auto' | 'smooth' = 'smooth') =>
	window.scrollTo({
		[position]: value,
		behavior: behavior
	});

export const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

export const getCurrentTime = () => {
	const currentTime = new Date().getMinutes() * 60000 + new Date().getHours() * 3600000;
	return currentTime;
};

export const changeLanguage = (language?: string, dispatch?: any) => {
	i18n.changeLanguage(language === 'de' ? 'en' : 'de');
	httpService
		.put(API_CONFIG.path.updateLanguage, {
			sprache: language === 'de' ? 'en' : 'de'
		})
		.then(() => {
			localStorage.setItem('lang', language === 'de' ? 'en' : 'de');
			dispatch(createAction(actionTypes.UPDATE_SITE_CONFIG, { language: language === 'de' ? 'en' : 'de' }));
		})
		.catch((err) => console.error(err));
};

export const onSetFiles = (files: File | null, type: string, field: string, setFieldValue: (field: string, value: any) => void) => {
	if (files) {
		const fileName = files ? files.name : '';
		const data = {
			file: files || ({} as File),
			name: fileName,
			type: files.type,
			url: URL.createObjectURL(files)
		};
		setFieldValue(field, data);
	}
};

export const convertDropDownObjectToValueArray = (dropDownObject: IDropDownOption[]) => dropDownObject.map((item) => item.value);

export const exportMaterialCourseList = async (
	data: IExportData[],
	selectedDateRange: string,
	language: string,
	t: TFunction<'translation', undefined, 'translation'>
) => {
	const splittedValue = data[0].datum?.split('.');
	const date = splittedValue ? new Date(`${splittedValue[1]}/${splittedValue[0]}/${splittedValue[2]}`) : null;
	const histDatum = date ? moment(date).subtract(DateRangeMapper[selectedDateRange][0], DateRangeMapper[selectedDateRange][1]).format() : null;
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Kursliste');
	const sheetName = `${data[0].listen_id}-1-${language === 'en' && data[0].listen_name_eng !== '' ? data[0].listen_name_eng : data[0].listen_name}`;
	worksheet.columns = customCsvHeaders(t);
	data.forEach((course: ICourseList) => {
		worksheet.addRow({
			name: (language === 'en' ? course.name_eng : course.name) || '-',
			faelligkeit_f: course.faelligkeit_f,
			datum: course.datum,
			hauptkurs: course.disallowed !== 1 && course.hauptkurs !== null ? Number(course.hauptkurs?.toFixed(course.nkstellen)) : null,
			hist_kurs: course.disallowed !== 1 && course.hist_kurs !== null ? Number(course.hist_kurs).toFixed(course.nkstellen) : null,
			hist_perf: course.disallowed !== 1 && course.hist_perf !== null ? Number(course.hist_perf.toFixed(course.nkstellen)) : null,
			hist_hoch: course.disallowed !== 1 && course.hist_hoch !== null ? Number(course.hist_hoch).toFixed(course.nkstellen) : null,
			hist_tief: course.disallowed !== 1 && course.hist_tief !== null ? Number(course.hist_tief).toFixed(course.nkstellen) : null,
			waehrung: course.waehrung,
			einheit: course.einheit,
			histDatum: histDatum ? formatDate(histDatum, 'DD.MM.YYYY') : ''
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Kursliste--${sheetName.replaceAll(' ', '_')}--${formatDate(new Date(), 'YYYY-MM-DD')}--${
		moment().hour() < 10 ? '0' + moment().hour() : moment().hour()
	}-${moment().minute() < 10 ? '0' + moment().minute() : moment().minute()}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportGroupUserList = async (data: IGroupUserListDownload[], t: TFunction<'translation', undefined, 'translation'>) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Kursliste');
	worksheet.columns = groupUserListHeader(t);
	data.forEach((item: IGroupUserListDownload) => {
		worksheet.addRow({
			group: item.groupName || '',
			user_id: item.benutzer_id || '',
			email: item.email || '',
			code: item.promotionalCode || ''
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	saveAs(new Blob([buf]), `Gruppen-Nutzerliste.xlsx`);
};

export const swapData = (data: IDashboardData[], oldIndex: number, newIndex: number) => {
	const itemsCopy = [...data];
	const oldIndexBackup = itemsCopy[oldIndex];
	itemsCopy[oldIndex] = itemsCopy[newIndex];
	itemsCopy[newIndex] = oldIndexBackup;
	return [...itemsCopy];
};

export const onSwapBetweenList = (oldIndex: number, newIndex: number, arrayOfPullData: IDashboardData[], arrayOfPutData: IDashboardData[]) => {
	const copyOfPullData = [...arrayOfPullData];
	const copyOfPutData = [...arrayOfPutData];
	if (copyOfPutData.length === 0) {
		copyOfPutData.push(copyOfPullData[oldIndex as number]);
		copyOfPullData.splice(oldIndex as number, 1);
		return { copyOfPullData: copyOfPullData, copyOfPutData: copyOfPutData };
	} else {
		if (newIndex !== 7) {
			if (arrayOfPutData[newIndex as number]) {
				copyOfPullData.splice(oldIndex as number, 1, arrayOfPutData[newIndex as number]);
			} else {
				copyOfPullData.splice(oldIndex as number, 1);
			}
			copyOfPutData.splice(newIndex as number, 1, arrayOfPullData[oldIndex as number]);
		}
		return { copyOfPullData: copyOfPullData, copyOfPutData: copyOfPutData };
	}
};

export const getNumberWithoutSpecialCharacters = (number: string) => {
	if (number.split('')[0] === '-') {
		return number.replaceAll('-', '').split('%')[0];
	} else {
		return number.split(' ')[0];
	}
};

export const exportAdminMaterialCourseList = async (data: IAdminSearchMaterialCourseList[]) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Stammdaten--Suche');
	worksheet.columns = adminAdvancedSearchCsvHeaders;
	data.forEach((course: IAdminSearchMaterialCourseList) => {
		worksheet.addRow({
			basis_symbol: course.basis_symbol,
			boerse: course.boerse,
			symbol: course.symbol,
			isin: course.isin || '-',
			editierbar: course.editierbar_,
			land: course.land,
			waehrung: course.waehrung,
			waehrung_ignore: course.waehrung_ignore,
			einheit: course.einheit,
			notierung: course.notierung,
			notierung_zusatz: course.notierung_zusatz,
			nkstellen: course.nkstellen,
			max_kursalter: course.max_kursalter,
			name_deu: course.name_deu,
			name_eng: course.name_eng,
			boersenname: course.boersenname,
			datenlieferant: course.datenlieferant,
			quelle: course.quelle,
			kunde: course.kunde,
			faelligkeit: course.faelligkeit,
			faelligkeit_f: course.faelligkeit_f,
			frei: course.frei,
			sichtbar: course.sichtbar || 0,
			name_zusatz: course.name_zusatz || '-',
			hinzugefuegt_am: course.hinzugefuegt_am,
			entfernt_am: course.entfernt_am,
			lagerbestand_symbol: course.lagerbestand_symbol,
			lagerbestand_boerse: course.lagerbestand_boerse,
			info: course.info,
			priority: course.priority,
			verantwortlich: course.verantwortlich,
			prozent_abweichung_erlaubt: course.prozent_abweichung_erlaubt
		});
	});

	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Stammdaten--Suche--${formatDate(new Date(), 'YYYY-MM-DD')}--${
		moment().hour() < 10 ? '0' + moment().hour() : moment().hour()
	}-${moment().minute() < 10 ? '0' + moment().minute() : moment().minute()}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportApiMasterData = async (data: IApiMasterData[], userId: number) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Stammdaten');
	worksheet.columns = apiMasterDataCsvHeaders;
	data.forEach((item: IApiMasterData) => {
		worksheet.addRow({
			symbol: item.symbol,
			boerse: item.boerse,
			basis_symbol: item.basis_symbol,
			isin: item.isin,
			faelligkeit: item.faelligkeit,
			faelligkeit_f: item.faelligkeit_f,
			sichtbar: item.sichtbar,
			land: item.land,
			waehrung: item.waehrung,
			einheit: item.einheit,
			notierung: item.notierung,
			notierung_zusatz: item.notierung_zusatz,
			name_deu: item.name_deu,
			name_eng: item.name_eng,
			name_zusatz: item.name_zusatz
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Stammdaten--Benutzer-${userId}--${formatDate(new Date(), 'YYYY-MM-DD')}--${
		moment().hour() < 10 ? '0' + moment().hour() : moment().hour()
	}-${moment().minute() < 10 ? '0' + moment().minute() : moment().minute()}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportAdminListEntries = async (data: AdminMaterialListEntries[], listenId: number | null, listenName: string | undefined) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Kurse');
	worksheet.columns = adminListEntriesHeader;
	data.forEach((item: AdminMaterialListEntries) => {
		worksheet.addRow({
			symbol: item.symbol,
			boerse: item.boerse,
			basis_symbol: item.basis_symbol,
			isin: item.isin,
			faelligkeit: item.faelligkeit,
			faelligkeit_f: item.faelligkeit_f,
			frei: item.frei,
			sichtbar: item.sichtbar,
			land: item.land,
			waehrung: item.waehrung,
			einheit: item.einheit,
			name_deu: item.name_deu,
			name_eng: item.name_eng,
			kurszeit: item.kurszeit,
			letzter: item.letzter,
			settlement: item.settlement,
			geld: item.geld,
			brief: item.brief,
			hauptkursfeld_soll: item.hauptkursfeld_soll
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Kurse--${listenId}--${listenName}--${formatDate(new Date(), 'YYYY-MM-DD')}--${
		moment().hour() < 10 ? '0' + moment().hour() : moment().hour()
	}-${moment().minute() < 10 ? '0' + moment().minute() : moment().minute()}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportAdminListMasterData = async (data: AdminMasterData[], listenId: number | null, listenName: string | undefined) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Stammdaten');
	worksheet.columns = adminMasterDataHeaders;
	data.forEach((item: AdminMasterData) => {
		worksheet.addRow({
			symbol: item.symbol,
			boerse: item.boerse,
			basis_symbol: item.basis_symbol,
			isin: item.isin,
			faelligkeit: item.faelligkeit,
			faelligkeit_f: item.faelligkeit_f,
			frei: item.frei,
			sichtbar: item.sichtbar,
			editierbar_: item.editierbar_,
			land: item.land,
			waehrung: item.waehrung,
			waehrung_ignore: item.waehrung_ignore,
			einheit: item.einheit,
			notierung: item.notierung,
			notierung_zusatz: item.notierung_zusatz,
			nkstellen: item.nkstellen,
			max_kursalter: item.max_kursalter,
			name_deu: item.name_deu,
			name_eng: item.name_eng,
			name_zusatz: item.name_zusatz,
			boersenname: item.boersenname,
			datenlieferant: item.datenlieferant,
			quelle: item.quelle,
			kunde: item.kunde,
			hinzugefuegt_am: item.hinzugefuegt_am,
			entfernt_am: item.entfernt_am,
			lagerbestand_symbol: item.lagerbestand_symbol,
			lagerbestand_boerse: item.lagerbestand_boerse,
			info: item.info,
			priority: item.priority,
			verantwortlich: item.verantwortlich,
			prozent_abweichung_erlaubt: item.prozent_abweichung_erlaubt
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Stammdaten--${listenId}--${listenName}--${formatDate(new Date(), 'YYYY-MM-DD')}--${
		moment().hour() < 10 ? '0' + moment().hour() : moment().hour()
	}-${moment().minute() < 10 ? '0' + moment().minute() : moment().minute()}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const downloadDataAsCSV = async (columns: IExcelHeader[], res: ICSVData[], sheetName: string, myMaterialCourseLists: ICourseList) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet(`${myMaterialCourseLists.listen_name}`);
	worksheet.columns = columns;
	res.map((object: any) => {
		worksheet.addRow(object);
	});
	const buf = await workbook.xlsx.writeBuffer();
	saveAs(new Blob([buf]), `${sheetName}.xlsx`);
};

export const exportMatfoxxOffersList = async (data: IMatfoxxOffersList[]) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Matfoxx--offers');
	worksheet.columns = matfoxxOffersListHeaders;
	data.forEach((item: IMatfoxxOffersList) => {
		worksheet.addRow({
			offer: item.offer,
			offer_eng: item.offer_eng,
			link: item.link,
			start_date: new Date(item.start_date),
			end_date: new Date(item.end_date)
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Matfoxx--offers-${formatDate(new Date(), 'YYYY-MM-DD')}--${
		moment().hour() < 10 ? '0' + moment().hour() : moment().hour()
	}-${moment().minute() < 10 ? '0' + moment().minute() : moment().minute()}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportMatfoxxJokesList = async (data: IMatfoxxJokesList[]) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet(data[0].joke.replaceAll('\n', ' ').replaceAll(':', ' '), {
		pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 }
	});
	worksheet.columns = matfoxxJokesListHeaders;
	data.forEach((item: IMatfoxxJokesList) => {
		worksheet.addRow({
			joke: item.joke,
			joke_eng: item.joke,
			date: new Date(item.date)
		});
		const row = worksheet.lastRow;
		row?.eachCell((cell, colNumber) => {
			if (colNumber === 1) {
				const lines = cell?.value?.toString().split('\n').length;
				const height = (lines && lines * 15) || 15; // Adjust the height of perticular row
				row.height = height;
			}
		});
	});

	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Matfoxx--jokes-${formatDate(new Date(), 'YYYY-MM-DD')}--${
		moment().hour() < 10 ? '0' + moment().hour() : moment().hour()
	}-${moment().minute() < 10 ? '0' + moment().minute() : moment().minute()}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportNewsListJokesList = async (data: IAllNewsCategoriesList[]) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet(data[0].name.replaceAll('\n', ' ').replaceAll(':', ' '), {
		pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 }
	});
	worksheet.columns = newsListHeaders;
	data.forEach((item: IAllNewsCategoriesList) => {
		worksheet.addRow({
			kategorie_id: item.id,
			kategorie: item.name,
			kategorie_eng: item.name_eng,
			quelle: item.source,
			grusel: item.scary
		});
		const row = worksheet.lastRow;
		row?.eachCell((cell, colNumber) => {
			if (colNumber === 1) {
				const lines = cell?.value?.toString().split('\n').length;
				const height = (lines && lines * 15) || 15; // Adjust the height of perticular row
				row.height = height;
			}
		});
	});

	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Nachrichten-Rubriken--${formatDate(new Date(), 'YYYY-MM-DD')}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

// Backpack master data download in admin
export const exportBackpackMasterData = async (
	data: IAdminBackpackMasterData,
	language: string,
	id: number,
	name: string,
	t: TFunction<'translation', undefined, 'translation'>
) => {
	const workbook = new ExcelJS.Workbook();

	//Create four different work sheet
	const materilaWorkSheet = workbook.addWorksheet(language === 'en' ? 'Materials' : 'Materialien');
	const newsWorkSheet = workbook.addWorksheet(language === 'en' ? 'News' : 'Nachricht');
	const forecastSheet = workbook.addWorksheet(language === 'en' ? 'Forecasts' : 'Prognosen');
	const pdfModuleSheet = workbook.addWorksheet(language === 'en' ? 'PDFs' : 'PDFs');
	const userSheet = workbook.addWorksheet(language === 'en' ? 'Users' : 'Benutzer');
	const userApiSheet = workbook.addWorksheet('API');

	// Add headers to each and every worksheet
	userSheet.columns = userListBackpackMasterDataHeaders(t);
	forecastSheet.columns = forecastBackpackMasterDataHeaders(t);
	pdfModuleSheet.columns = pdfModuleBackpackMasterDataHeaders(t);
	newsWorkSheet.columns = newsBackpackMasterDataHeaders(t);
	materilaWorkSheet.columns = apiMasterDataCsvHeaders;
	userApiSheet.columns = apiMasterDataCsvHeaders;

	// add row to news list work sheet

	data?.newsList?.forEach((item: IBackpackNewsList) => {
		newsWorkSheet.addRow({
			group: language === 'en' && item.gruppe_eng !== '' ? item.gruppe_eng : item.gruppe,
			category: language === 'en' && item.kategorie_eng !== '' ? item.kategorie_eng : item.kategorie
		});
	});

	// add rows to user list work sheet
	data?.userList?.forEach((item: IBackpackUserList) => {
		userSheet.addRow({
			name: item.vorname,
			lastName: item.nachname,
			email: item.email
		});
	});

	//add rows to forecast list work sheet
	data?.forecastList?.forEach((item: IBackpackForecastList) => {
		forecastSheet.addRow({
			group: language === 'en' && item.group_name_eng !== '' ? item.group_name_eng : item.group_name,
			title: language === 'en' && item.titel !== '' ? item.titel_eng : item.titel
		});
	});

	//add rows to forecast list work sheet
	data?.pdfModuleLisz?.forEach((item: IBackpackPdfModuleList) => {
		pdfModuleSheet.addRow({
			group: language === 'en' && item.group_name_eng !== '' ? item.group_name_eng : item.group_name,
			title: language === 'en' && item.titel !== '' ? item.titel_eng : item.titel
		});
	});

	// add rows to material list work sheet
	data?.materialList?.forEach((item: IBakcpackMaterialList) => {
		materilaWorkSheet.addRow({
			symbol: item.symbol,
			boerse: item.boerse,
			basis_symbol: item.basis_symbol,
			isin: item.isin,
			faelligkeit: item.faelligkeit,
			faelligkeit_f: item.faelligkeit_f,
			sichtbar: item.sichtbar,
			land: item.land,
			waehrung: item.waehrung,
			einheit: item.einheit,
			notierung: item.notierung,
			notierung_zusatz: item.notierung_zusatz,
			name_deu: item.name_deu,
			name_eng: item.name_eng,
			name_zusatz: item.name_zusatz
		});
	});

	data?.apimaterialList?.forEach((item: IBakcpackMaterialList) => {
		userApiSheet.addRow({
			symbol: item.symbol,
			boerse: item.boerse,
			basis_symbol: item.basis_symbol,
			isin: item.isin,
			faelligkeit: item.faelligkeit,
			faelligkeit_f: item.faelligkeit_f,
			sichtbar: item.sichtbar,
			land: item.land,
			waehrung: item.waehrung,
			einheit: item.einheit,
			notierung: item.notierung,
			notierung_zusatz: item.notierung_zusatz,
			name_deu: item.name_deu,
			name_eng: item.name_eng,
			name_zusatz: item.name_zusatz
		});
	});

	//set excel sheet name and download it
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Stammdaten--${id}--${name}--${formatDate(new Date(), 'YYYY-MM-DD')}--${
		moment().hour() < 10 ? '0' + moment().hour() : moment().hour()
	}-${moment().minute() < 10 ? '0' + moment().minute() : moment().minute()}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

type ITreeData = (
	| IAllMaterialType
	| IAllNewsType
	| IForecastsListType
	| IPdfModuleListType
	| IPdfModuleGroupData
	| IForecastGroupData
	| IMaterialGroupData
	| INewsGroupData
)[];

//Filter in tree structure
const filterTreeSub = (treeData: ITreeData, searchValue: string, language: string): ITreeData => {
	const copyOfTreeData = [...treeData];
	return copyOfTreeData.filter((item) => {
		const name = handleNameKey((item as IAllMaterialType | IMaterialGroupData).name_deu, item.name_eng, item.name, language).toLowerCase();
		const isNameMatched = name.includes(searchValue.toLowerCase());
		if (isNameMatched) {
			return true;
		} else if (!isEmpty(item.children || [])) {
			const filteredItems = filterTreeSub(item.children || [], searchValue, language);
			item.children = filteredItems as any;
			return !isEmpty(filteredItems);
		} else {
			return false;
		}
	});
};

export const filterTree = (treeData: ITreeData, searchValue: string, data: ITreeData, language: string) => {
	const copyOfTreeData = [...treeData];
	if (searchValue !== '') {
		const newData = copyOfTreeData.filter((item) => {
			const name = handleNameKey((item as IAllMaterialType | IMaterialGroupData).name_deu, item.name_eng, item.name, language).toLowerCase();
			const isNameMatched = name.includes(searchValue.toLowerCase());
			if (isNameMatched) {
				return true;
			} else {
				const filteredItems = filterTreeSub(item.children || [], searchValue, language);
				item.children = filteredItems as any;
				return !isEmpty(filteredItems);
			}
		});
		return newData;
	} else {
		return data;
	}
};

export const handleArticleData = (data: string) => {
	const regex =
		/<(?!pre\b)(div|p|blockquote|hr|h[1-6]|section|article|aside|header|footer|nav)(?:\s+[^>]*)?>|<\/(?!pre\b)(div|p|blockquote|hr|h[1-6]|section|article|aside|header|footer|nav)>/gi;
	const result = data.replace(regex, '<p>');
	return result;
};

export const replaceHashtagFromUrl = (word: string) => {
	return word.includes('#') ? word.replace('#', '%23') : word;
};

export const calculateAverages = (stockData: any) => {
	if (stockData.length === 0) {
		return {
			yearlyAverage: [],
			monthlyAverage: [],
			weeklyAverage: []
		};
	} else {
		// Sort the data by timestamp (if it's not already sorted)
		stockData.sort((a: any, b: any) => a.date - b.date);

		// Initialize variables to store aggregated data
		const yearlyData = {} as any;
		const monthlyData = {} as any;
		const weeklyData = {} as any;
		const key = Object.keys(stockData[stockData.length - 1]).filter((key) => key.includes('cv'));
		const priceKeys = Object.keys(stockData[stockData.length - 1]).filter((key) => key.includes('price'));
		const percentageKeys = Object.keys(stockData[stockData.length - 1]).filter((key) => key.includes('percentage'));

		for (const entry of stockData) {
			// Calculate year, month, and ISO week for each entry
			if (moment(entry.date).toDate() <= new Date()) {
				const year = moment(entry.date).year();
				const month = moment(entry.date).month();
				const isoWeek = moment(entry.date).isoWeek();
				const yearMonth = `${year}-${month + 1}`;
				const tempDate = moment(entry.date).date();
				let yearWeek;
				if (tempDate >= 29 && isoWeek === 1) {
					yearWeek = `${year + 1}-${isoWeek}`;
				} else if (tempDate <= 3 && isoWeek >= 52) {
					yearWeek = `${year - 1}-${isoWeek}`;
				} else {
					yearWeek = `${year}-${isoWeek}`;
				}
				const currentWeeklyData = weeklyData[yearWeek];
				weeklyData[yearWeek] = {
					...getDynamicKeys([...key, ...priceKeys, ...percentageKeys], currentWeeklyData, entry, isoWeek),
					date: currentWeeklyData ? currentWeeklyData.date : entry.date
				};

				// Update the sums for each timeframe
				const currentYearlyData = yearlyData[year];
				yearlyData[year] = {
					...getDynamicKeys([...key, ...priceKeys, ...percentageKeys], currentYearlyData, entry, isoWeek),
					date: currentYearlyData ? currentYearlyData.date : entry.date
				};

				const currentMonthlyData = monthlyData[yearMonth];
				monthlyData[yearMonth] = {
					...getDynamicKeys([...key, ...priceKeys, ...percentageKeys], currentMonthlyData, entry, isoWeek),
					date: currentMonthlyData ? currentMonthlyData.date : entry.date
				};
			}
		}

		// Calculate the averages
		const yearlyAverage = calculateWeeklyAverage(yearlyData, [...key, ...priceKeys, ...percentageKeys]);
		const monthlyAverage = calculateWeeklyAverage(monthlyData, [...key, ...priceKeys, ...percentageKeys]);
		const weeklyAverage = calculateWeeklyAverage(weeklyData, [...key, ...priceKeys, ...percentageKeys]);

		return {
			yearlyAverage,
			monthlyAverage,
			weeklyAverage
		};
	}
};

export const calculateInicatorAverages = (stockData: any) => {
	if (stockData.length === 0) {
		return {
			dailyAverage: [],
			monthlyAverage: [],
			weeklyAverage: []
		};
	} else {
		// Sort the data by timestamp (if it's not already sorted)
		stockData.sort((a: any, b: any) => a.date - b.date);

		// Initialize variables to store aggregated data
		const monthlyData = {} as any;
		const weeklyData = {} as any;
		for (const entry of stockData) {
			// Calculate year, month, and ISO week for each entry
			const year = new Date(entry.datum).getFullYear();
			const month = new Date(entry.datum).getMonth();
			const isoWeek = moment(entry.datum).isoWeek();
			const yearMonth = `${year}-${month + 1}`;
			const tempDate = new Date(entry.datum).getDate();
			let yearWeek;
			if (tempDate >= 29 && isoWeek === 1) {
				yearWeek = `${year + 1}-${isoWeek}`;
			} else if (tempDate <= 3 && isoWeek >= 52) {
				yearWeek = `${year - 1}-${isoWeek}`;
			} else {
				yearWeek = `${year}-${isoWeek}`;
			}

			const currentWeeklyData = weeklyData[yearWeek];
			weeklyData[yearWeek] = {
				...entry,
				letzter: currentWeeklyData ? currentWeeklyData.letzter + entry?.letzter : entry?.letzter,
				count: currentWeeklyData ? currentWeeklyData.count + 1 : 1,
				datum: currentWeeklyData ? currentWeeklyData.datum : entry.datum,
				date: currentWeeklyData ? currentWeeklyData.date : `${year}/${isoWeek < 10 ? `0` + `${isoWeek}` : isoWeek}`
			};

			// Update the sums for each timeframe

			const currentMonthlyData = monthlyData[yearMonth];
			monthlyData[yearMonth] = {
				...entry,
				letzter: currentMonthlyData ? currentMonthlyData.letzter + entry.letzter : entry.letzter,
				count: currentMonthlyData ? currentMonthlyData.count + 1 : 1,
				datum: currentMonthlyData ? currentMonthlyData.datum : entry.datum,
				date: currentMonthlyData ? currentMonthlyData.date : `${year}/${month + 1 < 10 ? `0` + `${month + 1}` : month + 1}`
			};
		}

		// Calculate the averages
		const monthlyAverage = calculateIndicatorWeeklyAverage(monthlyData);
		const weeklyAverage = calculateIndicatorWeeklyAverage(weeklyData);
		const dailyAverage = stockData.map((entry: any) => {
			return {
				...entry,
				date: entry.datum
			};
		});

		return {
			monthlyAverage,
			weeklyAverage,
			dailyAverage
		};
	}
};

export const calculateIndicatorWeeklyAverage = (data: any) => {
	return Object.values(data).map((value: any) => {
		return {
			...value,
			letzter: value.letzter / value.count
		};
	});
};

const getDynamicKeys = (keys: string[], currentData: any, entry: any, isoWeek: number) => {
	let obj: any = { ...currentData };
	for (let index = 0; index < keys.length; index++) {
		const key = keys[index];
		if (key.includes('price') || key.includes('percentage')) {
			obj = {
				...obj,
				[key]: currentData && currentData[key] ? currentData[key] + entry[key] : entry[key]
			};
		} else {
			if (entry.date === entry[key].d) {
				obj = {
					...obj,
					[key]: {
						openSum: currentData && currentData[key] ? currentData[key]?.openSum + entry[key]?.e : entry[key]?.e,
						highSum: currentData && currentData[key] ? currentData[key]?.highSum + entry[key]?.h : entry[key]?.h,
						lowSum: currentData && currentData[key] ? currentData[key]?.lowSum + entry[key]?.t : entry[key]?.t,
						closeSum: currentData && currentData[key] ? currentData[key]?.closeSum + entry[key]?.l : entry[key]?.l
					},
					count: currentData && currentData.count ? currentData.count + 1 : 1,
					week: currentData && currentData.week ? currentData.week : isoWeek
				};
			} else {
				obj = {
					...obj,
					week: currentData && currentData.week ? currentData.week : isoWeek
				};
			}
		}
	}
	return obj;
};

const getAverageOfEntry = (value: any, keys: string[]) => {
	let obj = {};
	for (let index = 0; index < keys.length; index++) {
		const key = keys[index];
		if (key.includes('price') || key.includes('percentage')) {
			obj = {
				...obj,
				[key]: value[key] / value.count
			};
		} else {
			obj = {
				...obj,
				[key]: {
					e: value[key]?.openSum / value.count,
					h: value[key]?.highSum / value.count,
					l: value[key]?.closeSum / value.count,
					t: value[key]?.lowSum / value.count
				}
			};
		}
	}
	return obj;
};

export const calculateWeeklyAverage = (data: any, keys: string[]) => {
	return Object.values(data).map((value: any) => {
		return {
			...getAverageOfEntry(value, keys),
			date: moment(value.date).toDate(),
			week: value.week
		};
	});
};

export const getChartDataBasedOnSpan = (span: number, data: any) => {
	switch (span) {
		case 7:
			return data.weeklyAverage;
		case 30:
			return data.monthlyAverage;
		case 365:
			return data.yearlyAverage;
		default:
			return data.yearlyAverage;
	}
};

export const getChartDataBasedOnGroupBy = (span: string, data: any) => {
	switch (span) {
		case 'daily':
			return data.dailyAverage;
		case 'monthly':
			return data.monthlyAverage;
		case 'weekly':
			return data.weeklyAverage;
		default:
			return data.dailyAverage;
	}
};

export const renderTicks = (index: number, isBarChart: boolean, chartName: string) => {
	switch (index) {
		case 0:
		case 1:
			return {
				xTicks: isBarChart ? 24 : chartName === 'dashboard' ? 6 : 10,
				yTicks: 12
			};
		case 2:
		case 3: {
			return {
				xTicks: 3,
				yTicks: 3
			};
		}
		case 4:
		case 5:
		case 6: {
			return {
				xTicks: 3,
				yTicks: 2
			};
		}
		default:
			return {
				xTicks: 12,
				yTicks: 15
			};
	}
};

const getPriceTime = (date: string, language: string) => {
	const format = language === 'de' ? 'DD.MM.YYYY' : 'MM/DD/YYYY';
	return moment(date).format(format);
};
export const chartInfoTableData = (
	information: IChartInformation,
	language: string,
	admin: string[],
	chartUpdate: { [key: string]: string },
	t: TFunction<'translation', undefined, 'translation'>
) => {
	let currencyValue = '';
	if (information.waehrung === '' && information.einheit === '') {
		currencyValue = '-';
	} else if (information.waehrung === '') {
		currencyValue = `- / ${information.einheit}`;
	} else if (information.einheit === '') {
		currencyValue = `${information.waehrung} / -`;
	} else {
		currencyValue = `${information.waehrung} / ${information.einheit}`;
	}
	if (admin.length) {
		return [
			{
				label: `${t(localizationConstants.name)}`,
				value:
					(language === 'en' && information.name_eng !== '' && information.name_eng) ||
					(information.name_deu !== '' ? information.name_deu : information.name),
				Icon: NameIcon
			},
			{
				label: `${t(localizationConstants.category)}`,
				value: (!isEmpty(information.category) && information.category.join(' , ')) || '-',
				Icon: CategotyIcon
			},
			{
				label: `${t(localizationConstants.frequency)}`,
				value: chartUpdate[information.notierung] || '-',
				Icon: FrequencyIcon
			},
			{
				label: `${t(localizationConstants.currency)} / ${t(localizationConstants.unit)}`,
				value: currencyValue,
				Icon: CurrencyUnitIcon
			},
			{
				label: `${t(localizationConstants.stockExchange)}`,
				value: information.boersenname || '-',
				Icon: StockExchangeIcon
			},
			{
				label: `${t(localizationConstants.source)}`,
				value: information.quelle || '-',
				Icon: SourceIcon
			},
			{
				label: `${t(localizationConstants.dataAvailability)}`,
				value: `${
					information.oldestDate && information.newestDate
						? getPriceTime(information.oldestDate, language) + '-' + getPriceTime(information.newestDate, language)
						: ''
				} `,
				Icon: DataAvailabilityIcon
			},
			{
				label: `${t(localizationConstants.addInfo)}`,
				value: information.notierung_zusatz || '-',
				Icon: AddInfoIcon
			},
			{
				label: `${t(localizationConstants.addedOn)}`,
				value: (information.hinzugefuegt_am && getPriceTime(information.hinzugefuegt_am, language)) || '-',
				Icon: AddedOnIcon
			},
			{
				label: `${t(localizationConstants.endedOn)}`,
				value: (information.entfernt_am && getPriceTime(information.entfernt_am, language)) || '-',
				Icon: EndedOnIcon
			}
		];
	} else {
		return [
			{
				label: `${t(localizationConstants.name)}`,
				value:
					(language === 'en' && information.name_eng !== '' && information.name_eng) ||
					(information.name_deu !== '' ? information.name_deu : information.name),
				Icon: NameIcon
			},
			{
				label: `${t(localizationConstants.category)}`,
				value: (!isEmpty(information.category) && information.category.join(' , ')) || '-',
				Icon: CategotyIcon
			},
			{
				label: `${t(localizationConstants.frequency)}`,
				value: chartUpdate[information.notierung] || '-',
				Icon: FrequencyIcon
			},
			{
				label: `${t(localizationConstants.currency)} / ${t(localizationConstants.unit)}`,
				value: currencyValue,
				Icon: CurrencyUnitIcon
			},
			{
				label: `${t(localizationConstants.stockExchange)}`,
				value: information.boersenname || '-',
				Icon: StockExchangeIcon
			},
			{
				label: `${t(localizationConstants.dataAvailability)}`,
				value: `${
					information.oldestDate && information.newestDate
						? getPriceTime(information.oldestDate, language) + '-' + getPriceTime(information.newestDate, language)
						: ''
				} `,
				Icon: DataAvailabilityIcon
			},
			{
				label: `${t(localizationConstants.addInfo)}`,
				value: information.notierung_zusatz || '-',
				Icon: AddInfoIcon
			},
			{
				label: `${t(localizationConstants.addedOn)}`,
				value: information.hinzugefuegt_am || '-',
				Icon: AddedOnIcon
			},
			{
				label: `${t(localizationConstants.endedOn)}`,
				value: information.entfernt_am || '-',
				Icon: EndedOnIcon
			}
		];
	}
};

export const barChartTickFormat = (d: number, data: any, span: string, t: TFunction<'translation', undefined, 'translation'>) => {
	if (span === '1w') {
		const tempDate = data[d].date.getDate();
		const year = data[d].date.getFullYear();
		const isoWeek = data[d].week;
		let updatedYear;
		if (tempDate >= 29 && isoWeek === 1) {
			updatedYear = year + 1;
		} else if (tempDate <= 3 && isoWeek >= 52) {
			updatedYear = year - 1;
		} else {
			updatedYear = year;
		}
		if (data[d].week === 1) {
			return `${moment(data[d].date).format('MMM')} ${updatedYear}`;
		}
		return `${t(localizationConstants.week)} ${data[d].week}`;
	} else if (span === '1y') {
		return moment(data[d].date).year();
	} else {
		return ''; // You can customize this part as needed
	}
};

export const germanNumberFormattter = (number: number, decimalPoint = 2) => {
	const numberFormatter = formatLocale({
		decimal: ',',
		thousands: '.',
		grouping: [3],
		currency: ['', '']
	});
	const formatter = `,.${decimalPoint}f`;
	const num = numberFormatter.format(formatter)(number);
	const splitNumber = num.split('.');
	if (splitNumber[1] === '00') {
		return numberFormatter.format(',.0f')(number);
	} else {
		return numberFormatter.format(formatter)(number);
	}
};

export const defineChartMargins = (chartSettings: IChartSettingsType[], isOnDashboard: boolean) => {
	const totalLeftAxis = filter(chartSettings, { axis: { value: ChartAxisPosition.LEFT_AXIS } }).length;
	const totalRightAxis = filter(chartSettings, { axis: { value: ChartAxisPosition.RIGHT_AXIS } }).length;
	if (totalLeftAxis > 0 && totalRightAxis > 0) {
		return { left: 80, right: 80 };
	} else if (totalLeftAxis > 0 && totalRightAxis === 0) {
		return { left: isOnDashboard ? 65 : 80, right: isOnDashboard ? 9 : 0 };
	} else if (totalRightAxis > 0 && totalLeftAxis === 0) {
		return { left: isOnDashboard ? 9 : 0, right: isOnDashboard ? 65 : 80 };
	}
};

export const chartTimeRangeMapper = (chartData: any): { [key: string]: number } => {
	if (!isEmpty(chartData)) {
		return {
			'1w': 7,
			'1m': 30,
			'3m': 90,
			'6m': 180,
			'1y': 365,
			'3y': 1095,
			'5y': 1825,
			'10y': 3650,
			ytd: getDifferenceDaysForOneYear(new Date(chartData[chartData.length - 1].date)),
			max: chartData.length
		};
	} else {
		return {};
	}
};

export const chartTimeRangeMapperForNumber = (chartData: any, span: string) => {
	if (parseInt(span) === 7) {
		return '1w';
	} else if (parseInt(span) === 30) {
		return '1m';
	} else if (parseInt(span) === 90) {
		return '3m';
	} else if (parseInt(span) === 180) {
		return '6m';
	} else if (parseInt(span) === 365) {
		return '1y';
	} else if (parseInt(span) === 1095) {
		return '3y';
	} else if (parseInt(span) === 1825) {
		return '5y';
	} else if (parseInt(span) === 3650) {
		return '10y';
	} else if (chartData.length === parseInt(span)) {
		return 'max';
	} else if (
		span === '1d' ||
		span === '1w' ||
		span === '1m' ||
		span === '3m' ||
		span === '6m' ||
		span === '1y' ||
		span === '3y' ||
		span === '5y' ||
		span === '10y' ||
		span === 'ytd' ||
		span === 'max'
	) {
		return span;
	} else {
		return '1y';
	}
};

export const getStartDateForExport = (span: string, endDate: string, chartData: any) => {
	if (span === '1d') {
		return moment(endDate).subtract(1, 'day').format();
	} else if (span === '1w') {
		return moment(endDate).subtract(1, 'week').format();
	} else if (span === '1m') {
		return moment(endDate).subtract(1, 'month').format();
	} else if (span === '3m') {
		return moment(endDate).subtract(3, 'months').format();
	} else if (span === '6m') {
		return moment(endDate).subtract(6, 'months').format();
	} else if (span === '1y') {
		return moment(endDate).subtract(1, 'year').format();
	} else if (span === '3y') {
		return moment(endDate).subtract(3, 'years').format();
	} else if (span === '5y') {
		return moment(endDate).subtract(5, 'years').format();
	} else if (span === '10y') {
		return moment(endDate).subtract(10, 'years').format();
	} else if (span === 'ytd') {
		if (isEmpty(chartData)) {
			return moment(endDate).startOf('year').format();
		} else {
			return moment(chartData[chartData.length - 1].date)
				.startOf('year')
				.format();
		}
	} else if (span === 'max') {
		return chartData[0].date;
	}
};

export const handleTenderDate = (inputDateString: string) => {
	const [year, month, day] = inputDateString.slice(0, 10).split('-');
	const [hours] = inputDateString.slice(11, 19).split('T');

	const formattedDate = `${day}.${month}.${year} ${hours}`;
	return formattedDate;
};

export const exportFutureTableData = async (firstTableData: string[][], secondTableData: string[]) => {
	const workbook = new ExcelJS.Workbook();
	const hour = moment().hour();
	const minute = moment().minute();
	const downloadSheetName = `Kurse--${formatDate(new Date(), 'YYYY-MM-DD')}--${hour < 10 ? '0' + hour : hour}-${
		minute < 10 ? '0' + minute : minute
	}`;
	const worksheet = workbook.addWorksheet(downloadSheetName);
	worksheet.addRows(firstTableData);
	worksheet.addRow([]);
	worksheet.addRows(secondTableData);
	worksheet.columns = [
		{ width: 30 }, // Width for column A
		{ width: 30 }, // Width for column B
		{ width: 30 } // Width for column C
		// Add more columns and widths as needed
	];
	const buf = await workbook.xlsx.writeBuffer();
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const getDifferenceInMinutes = (startTime: string, endTime: string) => {
	const date1 = new Date(startTime).getTime();
	const date2 = new Date(endTime).getTime();
	const diffInMilliseconds = Math.abs(date1 - date2);
	return Math.floor(diffInMilliseconds / (1000 * 60));
};

export const splitAlertOptions = (word: string) => {
	return word.match(/[^+/]+[+/]?/g) as string[];
};

export const getSeletectedOptionForAlert = (categories: string[], dropdownOptions: IDropDownOption[]) => {
	const categoriesOptions = [] as IDropDownOption[];

	categories.forEach((id) => {
		const cleanedId = id.replace(/[+/]/g, ''); // Remove '+' and '/' from the category ID
		dropdownOptions.forEach((option) => {
			if (option.value === cleanedId) {
				categoriesOptions.push({ label: option.label, value: id });
			}
		});
	});
	return categoriesOptions;
};
