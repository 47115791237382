import { every, get, has, isEmpty } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import { IDropDownOption } from 'shared/interface';

import {
	IAllMaterialType,
	IAllNewsType,
	IForecastGroupData,
	IMaterialGroupData,
	IMaterialObj,
	IMaterialObjChildren,
	INewsGroupData,
	IPdfModuleGroupData,
	IUnitInterface
} from 'features/admin/interface/admin';
import {
	API_CONFIG,
	downloadAdminMasterDataHeaders,
	downloadCountriesAndRegions,
	downloadCurrenciesHeader,
	downloadNotations,
	downloadTenadersCountriesHeaders,
	downloadUnitsHeaders
} from 'shared/constants/constants';
import httpService from 'shared/services/http.service';
import { formatDate } from 'shared/util/utility';
import moment from 'moment';

export const handleMaterialGroup = (item: IMaterialGroupData[], type: string) => {
	const tempArray: any = [];
	const tempHandleMaterialGroup = (item: IMaterialGroupData[], type: string) => {
		item.forEach((item: IMaterialGroupData) => {
			if (item.type === type) {
				const tempItem = type === 'list' ? item : { symbol: item.symbol, boerse: item.boerse };
				tempArray.push(tempItem);
			} else {
				tempHandleMaterialGroup(item.children, type);
			}
		});
	};
	tempHandleMaterialGroup(item, type);
	return tempArray;
};

export const gatherIdsInArray = (
	obj: any,
	arrayOfIds: { [key: string]: string }[],
	arrayOfListIds: string[],
	isPushIntoArray: boolean,
	isSearchEnabled: boolean
) => {
	if (obj.isChecked && !has(obj, 'children') && (obj as any).type === 'list_entries' && isPushIntoArray) {
		arrayOfIds.push({
			symbol: ((obj as any).symbol && (obj as any).symbol) || '',
			boerse: ((obj as any).boerse && (obj as any).boerse) || ''
		});
	}
	if (obj.isChecked && obj.type === 'list' && !isSearchEnabled) {
		arrayOfListIds.push(`${obj.id}`);
	}
	if (has(obj, 'children')) {
		if (obj.isChecked) {
			isPushIntoArray = isSearchEnabled;
		}
		for (const subChildKey in obj.children) {
			gatherIdsInArray(obj.children[subChildKey], arrayOfIds, arrayOfListIds, isPushIntoArray, isSearchEnabled);
		}
	}
};

export const convertArrayToKeyValue = (arr: IMaterialGroupData[], isBackpack: boolean) => {
	return arr.reduce((result: any, item: IMaterialGroupData) => {
		const { id, children, type, b_rechte_id, is_backpack, boerse, symbol, gueltig_bis } = item;
		const childrenObject = isEmpty(get(item, 'children', [])) ? false : convertArrayToKeyValue(children, isBackpack);
		if (type !== 'list_entries') {
			result[`${id}${type}`] = {
				isChecked: false,
				isLocked: !isEmpty(get(item, 'children', [])) ? false : true,
				isBackpackAuthorized: !isEmpty(get(item, 'children', [])) || isBackpack ? false : true,
				isAllselectedId: false,
				children: childrenObject,
				type: type,
				id: id
			};
		} else {
			result[`${id}${type}`] = {
				date: gueltig_bis ? new Date(gueltig_bis) : null,
				isLocked: b_rechte_id !== null && b_rechte_id ? true : false,
				isBackpackAuthorized: is_backpack ? is_backpack : false,
				isAllselectedId: false,
				boerse: boerse,
				symbol: symbol,
				type: type,
				id: id,
				isChecked: false
			};
		}
		return result;
	}, {});
};

export const handleSelectedRows = (ele: string, setSelectedRows: Dispatch<SetStateAction<string[]>>) => {
	setSelectedRows((sr: string[]) => {
		const tempArray = [...sr];
		const index = sr.findIndex((item) => item === ele);
		if (index === -1) {
			tempArray.push(ele);
		} else {
			tempArray.splice(index, 1);
		}
		return tempArray;
	});
};

export const handleCheckboxAndLock = (
	obj: IMaterialObj,
	checked: boolean,
	id: string,
	setMaterialObj: React.Dispatch<React.SetStateAction<IMaterialObj>>,
	isCheckedLock = false,
	isCheckboxSelected = false,
	date: Date | null = null
) => {
	setMaterialObj((materialObj) => {
		const copyOfObj = !isEmpty(obj) ? JSON.parse(JSON.stringify(obj)) : JSON.parse(JSON.stringify(materialObj));
		for (const parentKey in copyOfObj) {
			let isKeyMatched = false;
			const element = copyOfObj[parentKey];

			if (parentKey === id || id === '0group') {
				isKeyMatched = true;
			}
			if (isCheckboxSelected) {
				element.isChecked = isKeyMatched ? checked : element.isChecked;
			} else {
				element.isLocked = isKeyMatched ? checked : element.isLocked;
				element.isBackpackAuthorized = !checked && isKeyMatched ? checked : element.isBackpackAuthorized;
			}
			if (has(element, 'children')) {
				for (const childKey in element.children) {
					const childElement = element.children[childKey as keyof IMaterialObjChildren] as IMaterialObjChildren;
					if (parentKey !== id) {
						isKeyMatched = false;
					}
					if (childKey === id || id === '0group') {
						isKeyMatched = true;
					}
					checkboxAndLockRecursion(childElement, checked, id, isKeyMatched, parentKey, childKey, date, isCheckedLock, isCheckboxSelected);
				}
				if (every(Object.values(element.children), { isLocked: true })) {
					element.isLocked = true;
				} else {
					element.isLocked = false;
				}
				if (every(Object.values(element.children), { isBackpackAuthorized: true })) {
					element.isBackpackAuthorized = true;
				} else {
					element.isBackpackAuthorized = false;
				}
				if (isCheckboxSelected) {
					if (every(Object.values(element.children), { isChecked: true })) {
						element.isChecked = true;
					} else {
						element.isChecked = false;
					}
				}
			}
		}

		return copyOfObj;
	});
};

const checkboxAndLockRecursion = (
	obj: IMaterialObjChildren,
	checked: boolean,
	id: string,
	isKeyMatched: boolean,
	parentKey: string,
	childKey: string,
	date: Date | null,
	isCheckedLock: boolean,
	isCheckboxSelected: boolean
) => {
	if (isCheckboxSelected) {
		obj.isChecked = isKeyMatched ? checked : obj.isChecked;
	} else {
		obj.date = obj.isLocked ? (isCheckedLock ? null : obj.date) : null;
		obj.isLocked = isKeyMatched ? checked : obj.isLocked;
		obj.isBackpackAuthorized = !checked && isKeyMatched ? checked : obj.isBackpackAuthorized;
	}
	if (isCheckboxSelected && obj.date) {
		obj.date = new Date(obj.date);
	}
	if (has(obj, 'children')) {
		for (const subchildKey in obj.children) {
			let copyOfIsKeyMatched = isKeyMatched;
			const subChildElement = obj.children[subchildKey as keyof IMaterialObjChildren] as IMaterialObjChildren;
			if (parentKey !== id && childKey !== id && has(subChildElement, 'children')) {
				copyOfIsKeyMatched = false;
			}
			if (subchildKey === id || id === '0group') {
				copyOfIsKeyMatched = true;
			}
			checkboxAndLockRecursion(subChildElement, checked, id, copyOfIsKeyMatched, parentKey, childKey, date, isCheckedLock, isCheckboxSelected);
		}
		if (has(obj, 'children')) {
			if (every(Object.values(obj.children), { isLocked: true })) {
				obj.isLocked = true;
			} else {
				obj.isLocked = false;
			}
		}
		if (every(Object.values(obj.children), { isBackpackAuthorized: true })) {
			obj.isBackpackAuthorized = true;
		} else {
			obj.isBackpackAuthorized = false;
		}
		if (isCheckboxSelected && !isEmpty(obj.children)) {
			if (every(Object.values(obj.children), { isChecked: true })) {
				obj.isChecked = true;
			} else {
				obj.isChecked = false;
			}
		}
	} else {
		if (`${obj.id}${obj.type}` === id) {
			obj.date = date || null;
		}
	}
};

type GroupData = INewsGroupData | IForecastGroupData | IPdfModuleGroupData;

export const convertArrayToKeyValueForNewsAndForecast = (arr: GroupData[], section: string) => {
	return arr.reduce((result: any, item: GroupData) => {
		const { id, children, type, is_backpack } = item;
		const childrenObject =
			get(item, 'children', []).length === 0 ? false : convertArrayToKeyValueForNewsAndForecast(children as GroupData[], section);
		if (!isEmpty(get(item, 'children', []))) {
			result[`${id}${type}`] = {
				isAllselectedId: false,
				children: childrenObject,
				type: item.type,
				isChecked: false,
				isLocked: false
			};
		} else {
			result[`${id}${type}`] = {
				date: item.gueltig_bis ? new Date(item.gueltig_bis) : null,
				isChecked: false,
				isAllselectedId: false,
				id: id,
				type: item.type,
				isBackpackAuthorized: is_backpack ? is_backpack : false,
				isLocked: item[section === 'news' ? 'bn_id' : 'bp_id'] !== null
			};
		}
		return result;
	}, {});
};

export const findTotalLocks = (obj: IMaterialObjChildren, activeTab: string): { totalCount: number; totalLocks: number } => {
	let totalCount = 0;
	let totalLocks = 0;

	const traverseTree = (node: IMaterialObjChildren) => {
		if ((activeTab === 'material' && node.type === 'list_entries') || (activeTab === 'news' && node.type === 'list')) {
			totalCount++;
			if (node.isLocked) {
				totalLocks++;
			}
		}
		if (!isEmpty(node.children)) {
			for (const key in node.children) {
				if (Object.prototype.hasOwnProperty.call(node.children, key)) {
					const childNode = node.children[key as keyof IMaterialObjChildren] as IMaterialObjChildren;
					traverseTree(childNode);
				}
			}
		}
	};
	traverseTree(obj);
	return { totalCount, totalLocks };
};

export const handleNameKey = (name_deu: string, name_eng: string, name: string, language: string) => {
	const nameMap: { [key: string]: string[] } = {
		de: [name_deu, name],
		en: [name_eng, name_deu, name]
	};

	const names = nameMap[language];
	if (names) {
		for (const n of names) {
			if (n) {
				return n;
			}
		}
	}

	return '-';
};

export const extractGroupData = (data: (IAllMaterialType | IAllNewsType)[], language: string) => {
	const result: IDropDownOption[] = [];
	data.forEach((group) => {
		function traverseChildren(children: (IAllMaterialType | IAllNewsType)[], groupStr: string) {
			children.forEach((child) => {
				const label = `${groupStr}>${(language === 'en' && child.name_eng !== '' && child.name_eng) || child.name}`;
				if (child.type === 'group') {
					result.push({
						label: label,
						value: child.id
					});
					if (child.children.length) {
						traverseChildren(child.children, label);
					}
				}
			});
		}
		const groupStr = (language === 'en' && group.name_eng !== '' && group.name_eng) || group.name;
		if (group.type === 'group' && group.id !== 0) {
			result.push({
				label: groupStr,
				value: group.id
			});
		}
		traverseChildren(group.children, groupStr);
	});
	return result;
};

export const downloadUserList = (setLoading: (action: boolean) => void) => {
	setLoading(true);
	httpService
		.get(`${API_CONFIG.path.downloadUserList}`, undefined, { responseType: 'arraybuffer' })
		.then((response) => {
			const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(blob, `${response.headers['content-disposition'].split('=')[1]}`);
			setLoading(false);
		})
		.catch((error) => {
			console.error('error', error);
			setLoading(false);
		});
};

export const downloadLogins = (setActionLoading: (action: boolean) => void) => {
	setActionLoading(true);
	httpService
		.get(`${API_CONFIG.path.downloadLogins}`, undefined, { responseType: 'arraybuffer' })
		.then((response) => {
			const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(blob, `${response.headers['content-disposition'].split('=')[1]}`);
			setActionLoading(false);
		})
		.catch((error) => {
			console.error('error', error);
			setActionLoading(false);
		});
};

export const exportCurrenciesData = async (data: any) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Waehrunge');
	worksheet.columns = downloadCurrenciesHeader;
	data.forEach((item: any) => {
		const { waehrung, wfaktor, name_de, name_en, sichtbar, sortidx } = item;
		worksheet.addRow({
			waehrung,
			wfaktor,
			name_de,
			name_en,
			sichtbar,
			sortidx
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Waehrungen--${formatDate(new Date(), 'YYYY-MM-DD')}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportUnitsData = async (data: IUnitInterface[]) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Einheiten');
	worksheet.columns = downloadUnitsHeaders;
	data.forEach((item) => {
		const { einheit, name, name_en, efaktor, egruppe, sichtbar, egruppe_bez } = item;
		worksheet.addRow({
			einheit,
			name,
			name_en,
			efaktor,
			egruppe,
			sichtbar,
			egruppe_bez
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Einheiten--${formatDate(new Date(), 'YYYY-MM-DD')}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportCountriesAndRegionsData = async (data: any) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Laender-und-Regionen');
	worksheet.columns = downloadCountriesAndRegions;
	data.forEach((item: any) => {
		const { land, name_de, region, sichtbar, sortidx } = item;
		worksheet.addRow({
			land,
			name_de,
			region,
			sichtbar,
			sortidx
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Laender-und-Regionen--${formatDate(new Date(), 'YYYY-MM-DD')}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportNotationData = async (data: any) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Notierungen');
	worksheet.columns = downloadNotations;
	data.forEach((item: any) => {
		const { notierung, name, name_en, sortidx } = item;
		worksheet.addRow({
			notierung,
			name,
			name_en,
			sortidx
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Notierungen--${formatDate(new Date(), 'YYYY-MM-DD')}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportTendersCountries = async (data: any) => {
	const workbook = new ExcelJS.Workbook();

	Object.keys(data).forEach((key) => {
		const items = data[key];
		if (Array.isArray(items)) {
			// Create a worksheet for each key
			const worksheet = workbook.addWorksheet(key);

			const columns = downloadTenadersCountriesHeaders[key];
			worksheet.columns = columns;

			// Add rows dynamically
			items.forEach((item) => {
				worksheet.addRow(item);
			});
		}
	});
	const buf = await workbook.xlsx.writeBuffer();
	const downloadSheetName = `Country_list_${formatDate(new Date(), 'YYYY-MM-DD')}`;
	saveAs(new Blob([buf]), `${downloadSheetName}.xlsx`);
};

export const exportAdminMasterData = async (data: any, fileFormat: string) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Stammdaten--mbi');
	worksheet.columns = downloadAdminMasterDataHeaders;
	data.forEach((item: any) => {
		const {
			symbol,
			boerse,
			basis_symbol,
			isin,
			faelligkeit,
			faelligkeit_f,
			frei,
			sichtbar,
			editierbar_,
			land,
			waehrung,
			waehrung_ignore,
			einheit,
			notierung,
			notierung_zusatz,
			nkstellen,
			max_kursalter,
			name_deu,
			name_eng,
			name_zusatz,
			boersenname,
			datenlieferant,
			quelle,
			kunde,
			aid_kommentar_,
			prozent_abweichung_erlaubt,
			verantwortlich,
			info,
			priority
		} = item;
		worksheet.addRow({
			symbol,
			boerse,
			basis_symbol,
			isin,
			faelligkeit,
			faelligkeit_f,
			frei,
			sichtbar,
			editierbar_,
			land,
			waehrung,
			waehrung_ignore,
			einheit,
			notierung,
			notierung_zusatz,
			nkstellen,
			max_kursalter,
			name_deu,
			name_eng,
			name_zusatz,
			boersenname,
			datenlieferant,
			quelle,
			kunde,
			aid_kommentar_,
			prozent_abweichung_erlaubt,
			verantwortlich,
			info,
			priority
		});
	});
	const buf = await workbook.xlsx.writeBuffer();
	const csvData = Papa.unparse(data);
	const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
	const downloadSheetName = `Stammdaten--mbi--${formatDate(new Date(), 'YYYY-MM-DD')}--${
		moment().hour() < 10 ? '0' + moment().hour() : moment().hour()
	}-${moment().minute() < 10 ? '0' + moment().minute() : moment().minute()}`;
	if (fileFormat === 'csv') {
		saveAs(csvBlob, `${downloadSheetName}.csv`);
	} else {
		saveAs(new Blob([buf]), `${downloadSheetName}.${fileFormat}`);
	}
};
