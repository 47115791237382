import React, { ChangeEventHandler, FocusEventHandler, useRef } from 'react';
import { ErrorMessage, Field, FieldProps, FormikValues } from 'formik';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select, { ValueType } from 'react-select';
import TimePicker from 'react-time-picker';
import { useSelector } from 'react-redux';
import en from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import { has } from 'lodash';

import { DATE_FORMAT_MAPPER, reactSelectStyles, WINDOW_WIDTH } from 'shared/constants/constants';
import { CalenderIcon } from 'shared/icons/icon';
import { formatDate, windowScroll } from 'shared/util/utility';
import { State } from 'shared/interface';

import DropdownIndicator from '../dropdownIndicator/dropdownIndicator';
import CustomOption from './customOptions';
import FieldErrorMessage from './error';
export interface CheckboxOptions {
	name: string;
	value: string | number;
	id?: string | number;
	disabled?: boolean;
}

export interface DropDownOptions {
	label: string;
	value: string | number;
	component?: any;
}

export interface DropDownCurrency {
	title: string;
	symbol: string;
	id: string;
}
export interface FieldConfig {
	type: 'text' | 'textarea' | 'email' | 'password' | 'number' | 'dropdown' | 'checkbox' | 'dropdown' | 'radio' | 'date';
	label?: string | JSX.Element;
	name: string;
	className?: string;
	placeHolder?: string | JSX.Element;
	otherOptions?: {
		dropDownOptions?: DropDownOptions[];
		selectCurrency?: DropDownCurrency[];
		checkboxOptions?: CheckboxOptions[];
		isMultiSelect?: boolean;
	};
	maxDate?: Date;
	minDate?: Date | string;
	hideErrorMessage?: boolean;
	disabled?: boolean;
	maxLength?: number;
}
interface TextFieldProps {
	name: string;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
	placeholder?: string | JSX.Element;
	config: FieldConfig;
	isClearable?: boolean;
	asterisk?: boolean;
	value?: any;
	onSubmit?: (value: string) => void;
	readOnly?: boolean;
	type?: 'text' | 'textarea' | 'email' | 'password' | 'number' | 'checkbox' | 'dropdown' | 'radio' | 'date';
	className?: string;
	autoComplete?: string;
	showLabels?: boolean;
	onChange?: ChangeEventHandler<any>;
	onBlur?: FocusEventHandler<any>;
	disabled?: boolean;
	rows?: string;
	labelClassName?: string;
	min?: number;
	maxChar?: number;
	maxLength?: number;
	isDropDownSearchable?: boolean;
	menuIsOpen?: boolean;
}

const RenderInput: React.FC<TextFieldProps & { field: any }> = (props) => {
	const setCheckboxvalue: ChangeEventHandler<HTMLInputElement> = (evt) => {
		if (evt.target.value === 'selectAll') {
			let value: (string | number)[] = [];
			if (evt.target.checked) {
				value = geCheckboxOptions(props.config).map((item) => {
					return item.value;
				}) as (string | number)[];
			} else {
				value = [];
			}
			props.setFieldValue(props.config.name, value);
		} else {
			props.setFieldValue(props.config.name, getCheckboxValue(props.field, evt));
		}
	};

	const dropdownComponent = has(geDropDownOptions(props.config)[1], 'component')
		? {
				DropdownIndicator: DropdownIndicator,
				Option: (optionProps: any) => <CustomOption {...optionProps} />
		  }
		: { DropdownIndicator };

	switch (props.type) {
		// render text input in case of text, textarea, email, password and number
		case 'textarea':
			return (
				<>
					{props.showLabels && props.config.label && (
						<label className={`input-label-wrapper  ${props.labelClassName}`}>
							{props.config.label}
							{props.asterisk && <span className='required font--14px'>*</span>}
						</label>
					)}
					<div className={`${props.maxLength && 'position--relative'}`}>
						<textarea
							{...props.field}
							value={getValue(props.field.value)}
							id={props.name}
							className={`${props.className || ''} form-field overflow-hidden`}
							placeholder={props.placeholder}
							readOnly={props.readOnly}
							disabled={props.readOnly}
							autoComplete={`${props.autoComplete || 'off'}`}
							onBlur={WINDOW_WIDTH <= 640 ? () => windowScroll(0, 'top') : props.field.onBlur}
							onChange={props.onChange || props.field.onChange}
							rows={props.rows}
							maxLength={props.maxLength || props.config.maxLength}
						/>
						{props.maxLength && (
							<p className={`text-align-right font--10px mr-5 mt-6 position--absolute bottom-0 right-10 color--light-grey`}>
								{props.field.value.length}/{props.maxLength}
							</p>
						)}
					</div>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
				</>
			);
		case 'text':
		case 'email':
		case 'password':
		case 'number':
			return (
				<>
					{props.showLabels && props.config.label && (
						<label className={`font--12px line-height--17px font-weight--400 color--light-grey ${props.labelClassName}`}>
							{props.config.label}
							{props.asterisk && <span className='required'>*</span>}
						</label>
					)}
					<input
						{...props.field}
						value={getValue(props.field.value)}
						id={props.name}
						type={props.type}
						className={`${props.className || ''} ${
							(props.readOnly || props.config.disabled) && 'cursor-not-allowed'
						} form-field width--206px mr-8`}
						placeholder={props.placeholder}
						readOnly={props.readOnly}
						disabled={props.readOnly || props.config.disabled}
						autoComplete={`${props.autoComplete || 'off'}`}
						onChange={props.onChange || props.field.onChange || props.setFieldValue}
						onBlur={WINDOW_WIDTH <= 640 ? () => windowScroll(0, 'top') : props.field.onBlur}
						min={props.min}
						maxLength={props.maxLength || props.config.maxLength}
					/>
					<div className={`d-flex ${props.maxChar && 'justify-content-space-between'}`}>
						{!props.config.hideErrorMessage && (
							<ErrorMessage className='full-width' name={props.config.name} component={FieldErrorMessage} />
						)}
						{props.maxChar && (
							<p
								className={`text-align-right font--10px mr-5 mt-6 ${props.field.value.length === props.maxChar && 'error'} ${
									props.field.value.length !== 0 && 'full-width'
								}`}
							>
								{props.field.value.length}/{props.maxChar}
							</p>
						)}
					</div>
				</>
			);
		case 'radio':
			return (
				<div className='radio-input-wrapper'>
					<input
						{...props.field}
						type='radio'
						id={props.name + props.value}
						name={props.name}
						checked={props.field.value === props.value}
						value={props.value}
						onChange={(event) => {
							props.setFieldValue(props.name, props.value);
							props.onChange && props.onChange(event);
						}}
						disabled={props.disabled}
					/>
					<label
						className={`pointer mr-26 text--secondary ${props.field.value === props.value ? 'checked' : ''}
						${props.className ? props.className : ''} font--12px line-height--17px font-weight--400 text--dark-blue`}
						htmlFor={props.name + props.value}
					>
						{props.config.label}
					</label>
					{!props.config.hideErrorMessage && <ErrorMessage name={props.config.name} component={FieldErrorMessage} />}
				</div>
			);
		case 'checkbox':
			return (
				<div className='form-group d-flex align-items-baseline'>
					{props.config.label && <label className='pr-15 control-label font--16px font-weight--500'>{props.config.label}</label>}
					<div className='checkbox-wrapper'>
						{geCheckboxOptions(props.config).map((option) => {
							const isChecked = (props.field.value || []).map((key: any) => (key || '').toString()).includes(option.value.toString());
							return (
								<div className='checkbox-content mr-8 pl-6' key={option.value}>
									<label
										className={`text-capitalize checkbox-label font--14px line-height--20px font-weight--400 ${
											option.disabled ? 'disabled' : ''
										}`}
									>
										{option.name}
										<input
											placeholder={option.name}
											checked={isChecked}
											disabled={option.disabled}
											onChange={setCheckboxvalue}
											type='checkbox'
											name={option.name}
											value={option.value}
										/>
										<span className={`checkmark ${(!isChecked && 'border--1px-solid-grey') || ''}`} />
									</label>
								</div>
							);
						})}
					</div>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
				</div>
			);

		// render dorpdown when dropdown type is provided
		case 'dropdown':
			return (
				<>
					{props.showLabels && props.config.label && (
						<label className={`font--12px line-height--17px font-weight--400 color--light-grey ${props.labelClassName}`}>
							{props.config.label}
							{props.asterisk && <span className='required'>*</span>}
						</label>
					)}
					<Select
						placeholder={props.placeholder}
						styles={reactSelectStyles as any}
						value={props.field.value}
						isClearable={props.isClearable}
						name={props.config.name}
						options={geDropDownOptions(props.config)}
						onChange={(selectedOption: ValueType<DropDownOptions, false>) => {
							props.onChange || props.setFieldValue(props.config.name, selectedOption, true);
						}}
						onBlur={props.onBlur}
						classNamePrefix='select'
						disabled={props.disabled || props.readOnly}
						isMulti={false}
						className={`form-field no-padding width--206px cursor-pointer ${props.className}`}
						isSearchable={props.isDropDownSearchable ? true : false}
						components={dropdownComponent}
						menuIsOpen={props.menuIsOpen}
					/>
					<ErrorMessage className='full-width' name={props.config.name} component={FieldErrorMessage} />
				</>
			);

		default:
			return <></>;
	}
};

/**
 * common input field component
 * renders input based on the field configuration
 * @param props {field, form: {touched, errors}, ...props }
 */
const Input: React.FC<TextFieldProps> = (props) => {
	const fieldRender = ({ field }: { field: any }) => <RenderInput {...props} field={field} />;

	return <Field name={props.name} render={fieldRender} />;
};
registerLocale('en', en);
registerLocale('de', de);

const InputDatePicker: React.FC<FieldProps<FormikValues> & any> = (props) => {
	const fieldRender = ({ field }: { field: any }) => <RenderDatePicker {...props} field={field} />;
	return <Field name={props.name} render={fieldRender} />;
};

const RenderDatePicker = (props: any) => {
	const wrapperRef: any = useRef(null);
	function handleClickDatepickerIcon() {
		const datepickerElement = wrapperRef.current;
		if (datepickerElement) {
			datepickerElement.setFocus(true);
		}
	}
	const { language, dateFormat } = useSelector((state: State) => state.siteConfig);

	return (
		<>
			{props.label && (
				<label className='text-capitalize font--12px line-height--17px font-weight--400 color--light-grey'>
					{props.label}
					{props.asterisk && <span className='required'>*</span>}
				</label>
			)}
			<div className='date-picker--wrapper position--relative'>
				<DatePicker
					{...props.field}
					// key={dateFormate}
					value={(props.field.value && formatDate(props.field.value, DATE_FORMAT_MAPPER[dateFormat])) || null}
					ref={wrapperRef}
					todayButton={'Today'}
					disabled={props.disabled}
					inline={props.inline}
					className='form-field mb-8'
					autoComplete='off'
					locale={language}
					placeholderText={props.placeHolder || props.placeholder || ''}
					selected={props.field.value && props.field.value !== '' && new Date(props.field.value)}
					onChange={props.onChange}
					showMonthDropdown
					showYearDropdown
					showTimeSelect={props.showTimeSelect ? true : false}
					minDate={props.minDate}
					maxDate={props.maxDate}
					timeFormat={props.timeFormat || 'p'}
					timeIntervals={props.timeIntervals || 15}
					dateFormat={'Pp'}
					dropdownMode='select'
					fixedHeight
					isClearable={props.isClearable}
				/>
				<div
					className='icon--wrapper d-flex align-items-center justify-content-center mr-10 position--absolute cursor-pointer'
					onClick={handleClickDatepickerIcon}
				>
					<CalenderIcon className='calendar-icon' />
				</div>
			</div>
			<ErrorMessage name={props.name} component={FieldErrorMessage} />
		</>
	);
};

const InputTimePicker: React.FC<any> = (props) => {
	const fieldRender = ({ field }: { field: any }) => <RenderTimePicker {...props} field={field} />;
	return <Field name={props.name} render={fieldRender} />;
};

const RenderTimePicker = (props: any) => {
	return (
		<>
			{props.label && (
				<label className='text-capitalize font--12px line-height--17px font-weight--400 color--light-grey'>
					{props.asterisk && <span className='required'>*</span>}
				</label>
			)}
			<div className='date-picker--wrapper position--relative'>
				<TimePicker {...props} onChange={props.onChange} value={props.value} />
			</div>
			<ErrorMessage name={props.name} component={FieldErrorMessage} />
		</>
	);
};

/**
 * getCheckboxValue - returns check box value, after changing value with change event of html input element
 * @param field - field returned by formik
 * @param evt - html input change event, linked with checkbox input
 */
const getCheckboxValue = (field: any, evt: React.ChangeEvent<HTMLInputElement>) => {
	// if field value is empty, or null initially, assign it as empty array of strings
	if (!field.value) {
		field.value = [];
	}
	const index = field.value.indexOf(evt.target.value.toString());
	// if event gives `checked` = true, push target value to field value
	if (evt.target.checked || (evt.target.value === 'selectAll' && evt.target.checked)) {
		field.value.push(evt.target.value.toString());
	} else if (index !== -1) {
		// else remove target value from field value
		field.value.splice(index, 1);
	}
	// return value
	return field.value;
};

const geDropDownOptions = (config: FieldConfig) => (config.otherOptions || {}).dropDownOptions || [];

const geCheckboxOptions = (config: FieldConfig) => (config.otherOptions || {}).checkboxOptions || [];

const getValue = (value?: string | number) => {
	if (value === undefined || value === null) {
		return '';
	}
	return value;
};

export { Input, InputDatePicker, InputTimePicker };
