import { ChartIcon, DashboardListIcon, DashboardNewsIcon, TenderMenuIcon } from 'shared/icons/icon';

interface IProps {
	section: string;
}

const DragHandle = (props: IProps) => (
	<div className='dashboard-icon__wrapper'>
		{props.section === 'chart' && <ChartIcon className='width--32px width--32px' />}
		{props.section === 'news' && <DashboardNewsIcon />}
		{props.section === 'tender' && <TenderMenuIcon fill='white' />}
		{(props.section === 'list' || props.section === 'future-table') && <DashboardListIcon className='dashboard-icon' />}
	</div>
);

export default DragHandle;
