import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import { isEmpty } from 'lodash';

import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { API_CONFIG } from 'shared/constants/constants';
import { useWhitelabelConfigStatus } from 'shared/hooks/useWhitelabelConfigStatus';
import httpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { IAlertBaseCount, INavArray, ISubMenuType, State } from 'shared/interface';
import { createAction, debounce } from 'shared/util/utility';
import * as actionType from 'store/action-types';

import NavItem from './navItem';
import { DictionaryIcon, FaqIcon, InfoIcon, MatflixLogo, MinimizeSideMenuIcon, ShortMatflixLogo } from 'shared/icons/icon';
import PartnerLinkSlider from './partnerLinkSlider';
import { checkPermission } from 'shared/hoc/permission/permissionManager';
import { adminPermissionMapping } from 'shared/constants/permissionConstant';

interface IProps {
	className: string;
}

const SideNav: React.FC<IProps> = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { authData, userData, show_partner_section } = useSelector((state: State) => ({ ...state.auth, ...state.sideNavConfig }));
	const { material_status, news_status, whitelabelId, forecast_status, pdfModule_status } = useWhitelabelConfigStatus();
	const [openStatus, setOpenStatus] = useState<Record<string, boolean>>({});
	const [alertBaseCount, setAlertBaseCount] = useState<IAlertBaseCount>({} as IAlertBaseCount);
	const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
	const [isOpenSidebar, setIsOpenSidebar] = useState(true);

	const fetchAlertBaseCount = useCallback(() => {
		httpService
			.get(API_CONFIG.path.alertBaseCount)
			.then((response) => {
				if (!response.reqCanceled) {
					setAlertBaseCount(response);
				}
			})
			.catch((err) => {
				console.error('error', err);
			});
	}, []);

	const getShowPartnerLink = useCallback(() => {
		httpService
			.get(API_CONFIG.path.getShowPartnerLink)
			.then((response) => {
				dispatch(createAction(actionType.UPDATE_SHOW_PARTNER_SECTION, response));
			})
			.catch((error) => {
				console.error(error);
			});
	}, [dispatch]);

	useEffect(() => {
		getShowPartnerLink();
	}, [getShowPartnerLink]);

	useEffect(() => {
		fetchAlertBaseCount();
		const interval = setInterval(() => {
			fetchAlertBaseCount();
		}, 10000);

		return () => clearInterval(interval);
	}, [fetchAlertBaseCount]);

	const onresize = useCallback(() => {
		if (window.innerWidth <= 992) {
			document.body.classList.add('mini-navbar');
			setIsOpenSidebar(false);
		} else {
			document.body.classList.remove('mini-navbar');
			setIsOpenSidebar(true);
		}
	}, []);

	const throttleResize = useCallback(debounce(onresize, 300), []);

	useEffect(() => {
		throttleResize();
		window.addEventListener('resize', throttleResize);
		return () => {
			window.removeEventListener('resize', throttleResize);
		};
	}, [throttleResize]);

	const navArray = useMemo(() => {
		const copyOfNavarray: INavArray[] = JSON.parse(JSON.stringify(NAVARRAY));
		const conditions = [
			{ id: 'admin', condition: !checkPermission({ permission: adminPermissionMapping.superDataUserAdmin }) },
			{ id: 'tenders', condition: !userData.isTenderRights },
			{ id: 'materials', condition: material_status === 'hideall' },
			{ id: 'news', condition: news_status === 'hideall' || news_status === 'active' || news_status === 'active+original' },
			{ id: 'forecasts', condition: forecast_status === 'hideall' },
			{ id: 'pdfModule', condition: !userData.isPdfModuleRights }
		];

		conditions.forEach(({ id, condition }) => {
			const index = copyOfNavarray.findIndex((item) => item.id === id);
			if (condition) {
				if (index !== -1) {
					if (id === 'news' && (news_status === 'active' || news_status === 'active+original')) {
						copyOfNavarray[index].route = `/news/articleList/0?whitelabelId=${whitelabelId}`;
						(copyOfNavarray[index].subMenu as ISubMenuType[])[0].route = `/news/saveNews?whitelabelId=${whitelabelId}`;
					} else {
						copyOfNavarray.splice(index, 1);
					}
				}
			} else {
				if (id === 'admin' && userData.admin.length === 1 && userData.admin.includes('dataAdmin')) {
					copyOfNavarray[index].route = '/admin?tab=data';
				}
			}
		});
		return copyOfNavarray;
	}, [
		userData.isTenderRights,
		userData.admin,
		material_status,
		news_status,
		forecast_status,
		whitelabelId,
		pdfModule_status,
		userData.isPdfModuleRights
	]);

	// const handleClickOutside = () => {
	// 	setIsAlertOpen(false);
	// };

	const onClickSideNav = () => {
		setIsAlertOpen(!isAlertOpen);
	};

	const toggleSidebar = useCallback(() => {
		setIsOpenSidebar((isOpen) => {
			const newState = !isOpen;
			if (newState) {
				document.body.classList.remove('mini-navbar');
			} else {
				document.body.classList.add('mini-navbar');
			}
			return newState;
		});
	}, []);

	const toggleIsOpen = (name: string) => {
		setOpenStatus((prevState) => ({
			[name]: !prevState[name]
		}));
	};

	if (!authData.auth) {
		return <Navigate replace to='/login' />;
	}

	return (
		<nav
			className={`navbar-default navbar-static-side ${props.className || ''} d-flex align-items-center flex-column bg-color--primary`}
			role='navigation'
		>
			<ul className='nav full-width' id='side-menu'>
				<li className='nav-header cursor-pointer d-flex align-items-center justify-content-space-between'>
					<div className='profile-element ' onClick={() => navigate('/dashboard')}>
						{!isEmpty(userData.whitelable_config) ? (
							<img src={userData.whitelable_config.full_logo} crossOrigin='anonymous' className='full-width full-height' />
						) : (
							<MatflixLogo className='matflixx-icon' />
						)}
					</div>
					<div className='logo-element' onClick={() => navigate('/dashboard')}>
						{!isEmpty(userData.whitelable_config) ? (
							<img src={userData.whitelable_config.small_logo} crossOrigin='anonymous' className='full-width full-height' />
						) : (
							<ShortMatflixLogo />
						)}
					</div>
				</li>
				{!isEmpty(navArray) && !isEmpty(alertBaseCount) && (
					<>
						{navArray.map((nav, index) => (
							<NavItem
								id={nav.id}
								isOpen={openStatus[nav.id]}
								toggleIsOpen={toggleIsOpen}
								key={index}
								menu={nav}
								alertCount={alertBaseCount}
								onClickSideNav={onClickSideNav}
							/>
						))}
						{/* <Tooltip
							anchorId='alert'
							data-tooltip-place='right'
							classNameArrow='display--none'
							className='alert-popup no-padding'
							clickable
						>
							<AlertPopup alertBaseCount={alertBaseCount} fetchAlertBaseCount={fetchAlertBaseCount} onClose={handleClickOutside} />
						</Tooltip> */}
					</>
				)}
			</ul>
			<button
				className='navbar-minimalize top-50 btn navbar-back d-flex justify-content-center align-items-center position--absolute right-0'
				onClick={toggleSidebar}
			>
				<MinimizeSideMenuIcon />
			</button>
			<div
				className={`sidenav-bottom__wrapper full-width d-flex flex-column align-items-start justify-content-end ${!isOpenSidebar && 'mt-70'}`}
			>
				{show_partner_section && isOpenSidebar && <PartnerLinkSlider />}
				<ul className='bottom-nav nav full-width' id='side-menu'>
					<li className=' side-menu-shirnked'>
						<a href='https://www.matflixx.de/glossary' target='_blank' className='d-flex align-items-center position--relative'>
							<DictionaryIcon />
							<span className='nav-label font--16px ml-10 font-weight--500 line-height--22px'>
								<Translate text={localizationConstants.glossary} />
							</span>
						</a>
					</li>
					<li className=' side-menu-shirnked'>
						<a href='https://www.matflixx.de/faq' target='_blank' className='d-flex align-items-center position--relative'>
							<FaqIcon />
							<span className='nav-label font--16px ml-10 font-weight--500 line-height--22px'>FAQ</span>
						</a>
					</li>
					<li className=' side-menu-shirnked'>
						<a href='https://www.matflixx.de/impressum' target='_blank' className='d-flex align-items-center position--relative'>
							<InfoIcon />
							<span className='nav-label font--16px ml-10 font-weight--500 line-height--22px'>
								<Translate text={localizationConstants.imprint} />
							</span>
						</a>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default SideNav;

const NAVARRAY: INavArray[] = [
	{
		id: 'dashboard',
		name: 'dashboard',
		route: '/dashboard'
	},
	{
		id: 'materials',
		name: `${localizationConstants.materials}`,
		route: '/materials/allMaterials',
		subMenu: [
			{
				name: `${localizationConstants.allMaterials}`,
				route: '/materials/allMaterials'
			},
			{
				name: `${localizationConstants.myMaterials}`,
				route: '/materials/myMaterial'
			},
			{
				name: `${localizationConstants.search}`,
				route: '/materials/search'
			}
		]
	},
	{
		id: 'news',
		name: `${localizationConstants.news}`,
		route: `/news/articleList/0`,
		subMenu: [
			{
				name: `${localizationConstants.saveMessage}`,
				route: '/news/saveNews'
			}
		]
	},
	{
		id: 'tenders',
		name: `Tenders & more`,
		route: `/tenders/allTenders`,
		subMenu: [
			{
				name: `${localizationConstants.saveTenders}`,
				route: '/tenders/saveTenders/projects'
			}
		]
	},
	{
		id: 'forecasts',
		name: `${localizationConstants.forecasts}`,
		route: '/forecasts'
	},
	{
		id: 'pdfModule',
		name: `${localizationConstants.pdfModule}`,
		route: '/pdfModule'
	},
	{
		id: 'alert',
		name: `${localizationConstants.alerts}`,
		route: '/alert/allAlerts?tab=materials'
	},
	{
		id: 'admin',
		name: `${localizationConstants.admin}`,
		route: '/admin?tab=users'
	}
];
