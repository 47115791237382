import { t, TFunction } from 'i18next';
import * as Yup from 'yup';

import { errorMessages } from 'shared/constants/messages';
import localizationConstants from 'shared/util/translation/constants';
import { FILE_SIZE, passwordRegex, SUPPORTED_FORMATS, URL_REGEX } from './constants';

const loginFormValidationSchema = Yup.object().shape({
	email: Yup.string().email().required(errorMessages.required(`E-mail`)).strict(true),
	password: Yup.string()
		.required(errorMessages.required(`${t(localizationConstants.password)}`))
		.strict(true)
});

const addInfoValidationSchema = Yup.object().shape({
	info: Yup.string().required(errorMessages.required('Information')).strict(true)
});

const forgetPasswordValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email()
		.required(errorMessages.required(`${t(localizationConstants.email)}`))
		.strict(true)
});

const addBackpackValidationSchema = Yup.object().shape({
	name: Yup.string().required(errorMessages.required('Name')).strict(true),
	description: Yup.string().required(errorMessages.required('Comment')).strict(true)
});

const currencyValidation = Yup.object().shape({
	currency: Yup.string()
		.required(errorMessages.required(`${t(localizationConstants.currency)}`))
		.strict(true)
});
const resetPasswordValidationSchema = Yup.object().shape({
	// email: Yup.string().email()
	// 	.required(errorMessages.required('email')).strict(true),
	password: Yup.string().min(8, errorMessages.minLength('New Password', 8)).required(errorMessages.required('New Password')).strict(true),
	password_confirmation: Yup.string()
		.min(8, errorMessages.minLength('Password Confirmation', 8))
		.required(errorMessages.required('Password Confirmation'))
		.strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.password;
		})
});

const renameMyMaterialValidationSchema = Yup.object().shape({
	listen_name: Yup.string().required(errorMessages.required('rename')).strict(true)
});
// const customDateSpanChart = Yup.object().shape({
// 	von:Yup.date().min(new Date(),errorMessages.minLength(''))
// })
const materialSearchValidationSchema = Yup.object().shape({
	searchPattern: Yup.string()
		.min(3, `${t(localizationConstants.advanceSearchPlaceHolder)}`)
		.required(`${t(localizationConstants.searchPatterns)}`)
		.strict(true)
});

const changePasswordValidationSchema = (t: TFunction<'translation', undefined, 'translation'>) => {
	return Yup.object().shape({
		oldPassword: Yup.string()
			.required(`${t(localizationConstants.oldPasswordIsRequired)}`)
			.strict(true),
		newPassword: Yup.string()
			.required(`New password ${t(localizationConstants.isRequired)}`)
			.strict(true)
			.matches(passwordRegex, ''),
		newPasswordConfirmation: Yup.string()
			.required(errorMessages.required(`${t(localizationConstants.passwordConfirmation)}`))
			.strict(true)
			.test('match', `${t(localizationConstants.passwordMatch)}`, function (password) {
				return password === this.parent.newPassword;
			})
	});
};

// const priceAlertPopupValidationSchema = Yup.object().shape({
//     old_password: Yup.string()
//         .required(errorMessages.required('Old Password'))
//         .strict(true),
//     new_password: Yup.string()
//         .required(errorMessages.required('New Password'))
//         .strict(true)
//         .matches(
//             /(?=.*\d)(?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/,
//             errorMessages.customPasswordMessage
//         ),
//     new_password_confirmation: Yup.string()
//         .required(errorMessages.required('Password Confirmation'))
//         .strict(true)
//         .test(
//             'match',
//             errorMessages.passwordMatchValidate(),
//             function (password) {
//                 return password === this.parent.new_password;
//             }
//         ),
// });

const updateProfileValidationSchema = Yup.object().shape({
	name: Yup.string()
		.required(errorMessages.required(`${t(localizationConstants.name)}`))
		.strict(true),
	email: Yup.string()
		.email()
		.required(errorMessages.required(`${t(localizationConstants.email)}`))
		.strict(true)
});

const registrationValidationSchema = Yup.object().shape({
	anrede: Yup.object()
		.shape({})
		.required(`${t(localizationConstants.salutation)} ${t(localizationConstants.required)} `),
	vorname: Yup.string()
		.required(`${t(localizationConstants.firstname)} ${t(localizationConstants.required)} `)
		.strict(true),
	nachname: Yup.string()
		.required(`${t(localizationConstants.surname)} ${t(localizationConstants.required)} `)
		.strict(true),
	email: Yup.string()
		.email()
		.required(`${t(localizationConstants.email)} ${t(localizationConstants.required)} `)
		.strict(true)
		.lowercase(`${t(localizationConstants.email)} Must Be Lowercase String`),
	telefon: Yup.number()
		.min(1, 'Required at-least 1 number')
		.max(9999999999, 'Accept at-max 10 number')
		.required(`${t(localizationConstants.phoneNumber)} ${t(localizationConstants.required)} `)
		.typeError(`${t(localizationConstants.registrationValidationType)}`),
	firma: Yup.string()
		.required(`${t(localizationConstants.company)} ${t(localizationConstants.required)} `)
		.strict(true),
	position: Yup.string()
		.required(`${t(localizationConstants.position)} ${t(localizationConstants.required)} `)
		.strict(true),
	// postcode: Yup.number().required(errorMessages.required('Postcode')).strict(true),
	postcode: Yup.number()
		.min(1, 'Required at-least 1 number')
		.max(999999, 'Accept at-max 6 number')
		.required(`${t(localizationConstants.postcode)} ${t(localizationConstants.required)} `)
		.typeError(`${t(localizationConstants.registrationValidationType)}`),
	city: Yup.string()
		.required(`${t(localizationConstants.city)} ${t(localizationConstants.required)} `)
		.strict(true)
});

const newsEditValidationSchema = Yup.object().shape({
	datum: Yup.date()
		.required(`${t(localizationConstants.date)} ${t(localizationConstants.isRequired)}`)
		.typeError(`${t(localizationConstants.registrationValidationType)}`),
	titel: Yup.string().required(`${t(localizationConstants.title)} ${t(localizationConstants.isRequired)}`),
	grusel: Yup.string().required('grusel is required!'),
	inhalt: Yup.string().test('inhalt', 'inhalt is required', (value) => value !== '<p><br></p>')
});

const createPasswordValidationSchema = Yup.object().shape({
	password: Yup.string()
		.required('Kein Passwort angegeben.')
		.min(6, 'Das Passwort ist zu kurz - es sollte mindestens 8 Zeichen lang sein.')
		.max(24, 'Das Passwort ist zu lang - es sollte maximal 24 Zeichen lang sein.')
		.trim()
		.matches(passwordRegex, 'Das Passwort sollte einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.')
});

const generalDataValidationSchema = Yup.object().shape({
	oldPassword: Yup.mixed().required('Password is required!')
});

const forgotPasswordValidationSchema = Yup.object().shape({
	email: Yup.string().email().required('E-mail ist erforderlich!')
});
const setPeriodValidationSchema = Yup.object().shape({
	von: Yup.date()
		.required(`${t(localizationConstants.von)} ${t(localizationConstants.isRequired)}`)
		.nullable(),
	bis: Yup.date()
		.required(`${t(localizationConstants.bis)} ${t(localizationConstants.isRequired)}`)
		.nullable()
});

const shortcutLinkValidation = Yup.object().shape({
	link_name: Yup.string().required(`${t(localizationConstants.pleseSelectFieldMessage)}`),
	ziel_url: Yup.string()
		.url(`${t(localizationConstants.validUrlErrorMessage)}`)
		.required(`${t(localizationConstants.enterValidUrlAddress)}`)
});

const CreateCostModalValidationSchema = Yup.object().shape({
	modal_name: Yup.string()
		.trim('Model name does not contain spaces before and after string')
		.required(errorMessages.required('Model name'))
		.strict(true),
	currency: Yup.mixed().test('isSelected', errorMessages.required('File'), (value) => !!value?.value),
	sector_id: Yup.mixed().when('is_visible', {
		is: 1,
		then: Yup.mixed().test('isSelected', errorMessages.required('File'), (value) => !!value?.value)
	}),
	ingridients: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required(errorMessages.required('Name'))
		})
	)
	// 	{
	// 		id: '',
	// 		rawMaterial: '',
	// 		price: 0,
	// 		amount: 0,
	// 		unit: {} as DropDownOptions,
	// 		ratio: '',
	// 		symbol: '',
	// 		boerse: ''
	// 	}
	// ]
});

const matfoxxOffersValidation = Yup.object().shape({
	start_date: Yup.date()
		.required(`${t(localizationConstants.date)} ${t(localizationConstants.isRequired)}`)
		.typeError(`${t(localizationConstants.registrationValidationType)}`),
	end_date: Yup.date()
		.required(`${t(localizationConstants.date)} ${t(localizationConstants.isRequired)}`)
		.typeError(`${t(localizationConstants.registrationValidationType)}`),
	offer: Yup.string().required(`Offer German ${t(localizationConstants.isRequired)}`),
	offer_eng: Yup.string().required(`Offer English ${t(localizationConstants.isRequired)}`),
	link: Yup.string()
		.matches(URL_REGEX, 'Enter correct url!')
		.required(`Link ${t(localizationConstants.isRequired)}`)
});

const matfoxxProfileValidation = Yup.object().shape({
	notifications: Yup.string().required(`${t(localizationConstants.required)}`),
	notification_period: Yup.string().required(`${t(localizationConstants.required)}`),
	from: Yup.string().when('notification_period', {
		is: '1',
		then: Yup.string().required(`${t(localizationConstants.required)}`)
	}),
	until: Yup.string().when('notification_period', {
		is: '1',
		then: Yup.string().required(`${t(localizationConstants.required)}`)
	})
});

const matfoxxJokesValidation = Yup.object().shape({
	joke: Yup.string().required(`Offer ${t(localizationConstants.isRequired)}`),
	joke_eng: Yup.string().required(`Offer ${t(localizationConstants.isRequired)}`)
});
const addNewPriceListValidation = Yup.object().shape({
	german: Yup.string().required(`${t(localizationConstants.pleseSelectFieldMessage)}`),
	english: Yup.string().required(`${t(localizationConstants.pleseSelectFieldMessage)}`)
});

// const chartSettingValidationSchema = Yup.object().shape({
// 	chartSettings: Yup.array().of(
// 		Yup.object().shape({
// 			indicators: Yup.array().of(
// 				Yup.object().shape({
// 					calculatedValue: Yup.string()
// 						.required('Value is required') // Add a custom error message
// 						.min(1, 'Value must not be empty')
// 						.notOneOf(['0'], 'Value cannot be 0')
// 				})
// 			),
// 			history: Yup.string()
// 				.required()
// 				.test('all-selected-none', 'All selected options should be either "none" or a valid value', (value) => {
// 					const nonNoneValues = value.filter((option: any) => option.value !== 'none');
// 					return nonNoneValues.length === 0;
// 				})
// 			})
// 			)
// 		});
// history: Yup.string().test('isNoneSelected', 'At least one history field must be "None"', function () {
// 	// Get the values of all history fields
// 	const historyValues: any = this.parent.chartSettings.map((setting: any) => setting.history);

// 	// Check if all history fields are "None" or if there's a non-"None" value
// 	return historyValues.every((val: any) => val === 'none') || historyValues.some((val: any) => val !== 'none');
// })

// const chartSettingValidationSchema = Yup.object().shape({
// 	chartSettings: Yup.array().of(
// 		Yup.object().shape({
// 			indicators: Yup.array().of(
// 				Yup.object().when('label', {
// 					is: (value: any) => {
// 						console.log(value);
// 						return true;
// 					},
// 					then: Yup.object().shape({
// 						calculatedValue: Yup.string()
// 							.required('Value is required') // Add a custom error message
// 							.min(1, 'Value must not be empty')
// 							.notOneOf(['0'], 'Value cannot be 0')
// 					})
// 				})
// 			)
// 		})
// 	)
// });

const chartSettingValidationSchema = (t: TFunction<'translation', undefined, 'translation'>) =>
	Yup.object().shape({
		chartSettings: Yup.array().of(
			Yup.object().shape({
				indicators: Yup.array().of(
					Yup.object().shape({
						value: Yup.string(),
						datum: Yup.number().when('value', {
							is: 'sto',
							then: Yup.number()
								.typeError(`${t(localizationConstants.date)} ${t(localizationConstants.mustBeNumber)}`)
								.required(`${t(localizationConstants.date)} is required`)
								.test('is-not-nan', `${t(localizationConstants.date)} must not be NaN`, (value) => !isNaN(value))
						}),
						tage: Yup.number().when('value', {
							is: 'sto',
							then: Yup.number()
								.typeError(`${t(localizationConstants.days)} ${t(localizationConstants.mustBeNumber)}`)
								.required(`${t(localizationConstants.days)} is required`)
								.test('is-not-nan', `${t(localizationConstants.days)} must not be NaN`, (value) => !isNaN(value))
						}),
						k: Yup.number().when('value', {
							is: 'sto',
							then: Yup.number()
								.typeError(`"K" ${t(localizationConstants.mustBeNumber)}`)
								.required('"K" is required')
								.test('is-not-nan', 'K must not be NaN', (value) => !isNaN(value))
						}),
						signal: Yup.number().when('value', {
							is: 'macd',
							then: Yup.number()
								.typeError(`${t(localizationConstants.signal)} ${t(localizationConstants.mustBeNumber)}`)
								.required(`${t(localizationConstants.signal)} is required`)
								.test('is-not-nan', `${t(localizationConstants.signal)} must not be NaN`, (value) => !isNaN(value))
						}),
						lang: Yup.number().when('value', {
							is: 'macd',
							then: Yup.number()
								.typeError(`${t(localizationConstants.long)} ${t(localizationConstants.mustBeNumber)}`)
								.required(`${t(localizationConstants.long)} is required`)
								.test('is-not-nan', `${t(localizationConstants.long)} must not be NaN`, (value) => !isNaN(value))
						}),
						kurz: Yup.number().when('value', {
							is: 'macd',
							then: Yup.number()
								.typeError(`${t(localizationConstants.short)} ${t(localizationConstants.mustBeNumber)}`)
								.required(`${t(localizationConstants.short)} is required`)
								.test('is-not-nan', `${t(localizationConstants.short)} must not be NaN`, (value) => !isNaN(value))
						}),
						calculatedValue: Yup.mixed().when('value', {
							is: (value) => value === 'sto' || value === 'macd',
							then: Yup.mixed(),
							otherwise: Yup.number()
								.typeError(`${t(localizationConstants.value)} ${t(localizationConstants.mustBeNumber)}`)
								.nullable()
						})
					})
				)
			})
		)
	});

const groupValidationSchemaForData = (t: TFunction<'translation', undefined, 'translation'>) => {
	return Yup.object().shape({
		category: Yup.string(),
		german: Yup.string().required(errorMessages.required(t(localizationConstants.germanName))),
		english: Yup.string().required(errorMessages.required(t(localizationConstants.englishName))),
		file: Yup.mixed().when('category', {
			is: 'group',
			then: Yup.mixed()
				.test('isSelected', errorMessages.required('File'), (value) => !!value?.url)
				.test('fileSize', `${t(localizationConstants.imageValidationMessage)}`, (value) => value && value?.file?.size <= FILE_SIZE)
				.test(
					'fileFormat',
					`${t(localizationConstants.imageFormateValidationMessage)}`,
					(value) => value && SUPPORTED_FORMATS.includes(value.type)
				)
		}),
		activeFile: Yup.object().when('category', {
			is: 'group',
			then: Yup.object().shape({
				url: Yup.string().required(errorMessages.required(t(localizationConstants.activeIcon)))
			})
		}),
		inactiveFile: Yup.object().when('category', {
			is: 'group',
			then: Yup.object().shape({
				url: Yup.string().required(errorMessages.required(t(localizationConstants.inActiveIcon)))
			})
		}),
		group: Yup.mixed().when('category', { is: 'subgroup', then: Yup.mixed().nullable().required('Group is required') })
	});
};

const groupConfigValidationSchema = (t: TFunction<'translation', undefined, 'translation'>) =>
	Yup.object().shape({
		groupName: Yup.string()
			.trim()
			.matches(/^\S+(?:\s{1}\S+)*$/, `${t(localizationConstants.groupNameWhiteSpaceValidationMessage)}`) // Ensure no leading or trailing spaces and no more than one whitespace between words
			.max(50, `${t(localizationConstants.groupName50CharacterValidation)}`)
			.required(errorMessages.required(`${t(localizationConstants.groupName)}`)),
		promotionCode: Yup.string().required(errorMessages.required(`${t(localizationConstants.promotionCode)}`))
	});

const whiteLableValidationSchema = () => {
	return Yup.object().shape({
		name: Yup.string().trim(errorMessages.required('White label name')).required(errorMessages.required('White label name')),
		full_logo: Yup.object().shape({
			url: Yup.string().required(errorMessages.required('Full logo'))
		}),
		small_logo: Yup.object().shape({
			url: Yup.string().required(errorMessages.required('Small logo'))
		})
	});
};

const tenderAlertValidationSchema = (t: TFunction<'translation', undefined, 'translation'>) => {
	return Yup.object().shape({
		word: Yup.array()
			.of(Yup.object())
			.min(1, errorMessages.required(t(localizationConstants.keywords)))
			.typeError(errorMessages.required(t(localizationConstants.keywords)))
	});
};

const newsAlertValidationSchema = (t: TFunction<'translation', undefined, 'translation'>) => {
	return Yup.object().shape({
		keyword: Yup.array()
			.of(Yup.object())
			.min(1, errorMessages.required(t(localizationConstants.keywords)))
			.typeError(errorMessages.required(t(localizationConstants.keywords))),
		category: Yup.array()
			.of(Yup.object())
			.min(1, errorMessages.required(t(localizationConstants.category)))
			.typeError(errorMessages.required(t(localizationConstants.category))),
		name: Yup.string().required(errorMessages.required(t(localizationConstants.name)))
	});
};

const profileUpdateValidation = Yup.object().shape({
	anrede: Yup.object()
		.shape({})
		.required(`${t(localizationConstants.salutation)} ${t(localizationConstants.required)} `),
	vorname: Yup.string()
		.max(30, `${t(localizationConstants.firstNameCharacterLengthValidation)}`)
		.required(`${t(localizationConstants.firstname)} ${t(localizationConstants.required)}`),
	nachname: Yup.string()
		.max(30, `${t(localizationConstants.lastNameCharacterLengthValidation)}`)
		.required(`${t(localizationConstants.surname)} ${t(localizationConstants.required)}`),
	telefon: Yup.string()
		.matches(/^\d+$/, `${t(localizationConstants.phoneNumber)} ${t(localizationConstants.mustBeNumber)}`)
		.min(1, `${t(localizationConstants.phoneNumber)} ${t(localizationConstants.required)}`)
		.max(9999999999, `${t(localizationConstants.acceptAtmax10Numbers)}`)
		.required(`${t(localizationConstants.phoneNumber)} ${t(localizationConstants.required)}`)
});

const createNewForecastBoxValidation = (t: TFunction<'translation', undefined, 'translation'>) => {
	return Yup.object().shape({
		german: Yup.string()
			.max(50, `${t(localizationConstants.germanNameCharacterLengthValidation)}`)
			.required(errorMessages.required(t(localizationConstants.germanName))),
		english: Yup.string()
			.max(50, `${t(localizationConstants.englishNameCharacterLengthValidation)}`)
			.required(errorMessages.required(t(localizationConstants.englishName))),
		file: Yup.mixed()
			.required('A file is required')
			.test('fileSize', `${t(localizationConstants.imageValidationMessage)}`, (value) => value && value?.file?.size <= FILE_SIZE)
			.test(
				'fileFormat',
				`${t(localizationConstants.imageFormateValidationMessage)}`,
				(value) => value && SUPPORTED_FORMATS.includes(value.type)
			)
	});
};
const createNewPdfModuleBoxValidation = (t: TFunction<'translation', undefined, 'translation'>) => {
	return Yup.object().shape({
		german: Yup.string()
			.max(50, `${t(localizationConstants.germanNameCharacterLengthValidation)}`)
			.required(errorMessages.required(t(localizationConstants.germanName))),
		english: Yup.string()
			.max(50, `${t(localizationConstants.englishNameCharacterLengthValidation)}`)
			.required(errorMessages.required(t(localizationConstants.englishName))),
		file: Yup.mixed()
			.required('A file is required')
			.test('fileSize', `${t(localizationConstants.imageValidationMessage)}`, (value) => value && value?.file?.size <= FILE_SIZE)
			.test(
				'fileFormat',
				`${t(localizationConstants.imageFormateValidationMessage)}`,
				(value) => value && SUPPORTED_FORMATS.includes(value.type)
			)
	});
};

const groupValidationSchemaForForecast = (t: TFunction<'translation', undefined, 'translation'>) => {
	return Yup.object().shape({
		german: Yup.string().required(errorMessages.required(t(localizationConstants.germanName))),
		english: Yup.string().required(errorMessages.required(t(localizationConstants.englishName))),
		file: Yup.mixed()
			.required('A file is required')
			.test('fileSize', `${t(localizationConstants.imageValidationMessage)}`, (value) => {
				return value && value?.file?.size <= FILE_SIZE;
			})
			.test(
				'fileFormat',
				`${t(localizationConstants.imageFormateValidationMessage)}`,
				(value) => value && SUPPORTED_FORMATS.includes(value.type)
			),
		group: Yup.mixed().required('Group is required')
	});
};

const groupValidationSchemaForPdfModule = (t: TFunction<'translation', undefined, 'translation'>) => {
	return Yup.object().shape({
		german: Yup.string().required(errorMessages.required(t(localizationConstants.germanName))),
		english: Yup.string().required(errorMessages.required(t(localizationConstants.englishName))),
		file: Yup.mixed()
			.required('A file is required')
			.test('fileSize', `${t(localizationConstants.imageValidationMessage)}`, (value) => {
				return value && value?.file?.size <= FILE_SIZE;
			})
			.test(
				'fileFormat',
				`${t(localizationConstants.imageFormateValidationMessage)}`,
				(value) => value && SUPPORTED_FORMATS.includes(value.type)
			),
		group: Yup.mixed().required('Group is required')
	});
};

const subGroupValidationSchemaForForecast = (t: TFunction<'translation', undefined, 'translation'>) =>
	Yup.object().shape({
		german: Yup.string().required(errorMessages.required(t(localizationConstants.germanName))),
		english: Yup.string().required(errorMessages.required(t(localizationConstants.englishName))),
		group: Yup.mixed().required('Group is required'),
		naming: Yup.string().required(`Naming ${t(localizationConstants.required)}`),
		prefix: Yup.string().when('naming', {
			is: 'prefix', // Assuming 'prefix' is the value of the naming field when prefix should be required
			then: Yup.string().required(`Prefix ${t(localizationConstants.required)}`)
		})
	});

const subGroupValidationSchemaForPdfModule = (t: TFunction<'translation', undefined, 'translation'>) =>
	Yup.object().shape({
		german: Yup.string().required(errorMessages.required(t(localizationConstants.germanName))),
		english: Yup.string().required(errorMessages.required(t(localizationConstants.englishName))),
		group: Yup.mixed().required('Group is required'),
		naming: Yup.string().required(`Naming ${t(localizationConstants.required)}`),
		prefix: Yup.string().when('naming', {
			is: 'prefix', // Assuming 'prefix' is the value of the naming field when prefix should be required
			then: Yup.string().required(`Prefix ${t(localizationConstants.required)}`)
		})
	});

export {
	addBackpackValidationSchema,
	addInfoValidationSchema,
	addNewPriceListValidation,
	changePasswordValidationSchema,
	chartSettingValidationSchema,
	CreateCostModalValidationSchema,
	createNewForecastBoxValidation,
	createNewPdfModuleBoxValidation,
	createPasswordValidationSchema,
	currencyValidation,
	forgetPasswordValidationSchema,
	forgotPasswordValidationSchema,
	generalDataValidationSchema,
	groupConfigValidationSchema,
	groupValidationSchemaForData,
	groupValidationSchemaForForecast,
	groupValidationSchemaForPdfModule,
	loginFormValidationSchema,
	materialSearchValidationSchema,
	matfoxxJokesValidation,
	matfoxxOffersValidation,
	matfoxxProfileValidation,
	newsAlertValidationSchema,
	newsEditValidationSchema,
	profileUpdateValidation,
	registrationValidationSchema,
	renameMyMaterialValidationSchema,
	resetPasswordValidationSchema,
	setPeriodValidationSchema,
	shortcutLinkValidation,
	subGroupValidationSchemaForForecast,
	subGroupValidationSchemaForPdfModule,
	tenderAlertValidationSchema,
	updateProfileValidationSchema,
	whiteLableValidationSchema
};
